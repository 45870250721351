import { TypePolicies } from '@apollo/client';
import { findAllOffersPolicy } from '@app/features/offers/pages/offers-page/in-memory-cache/offers-page';

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      ...findAllOffersPolicy.fields
    }
  }
};
