import { Pipe, PipeTransform } from '@angular/core';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { GuideClientDetailsModel } from '../models/guide-client-details.model';

/**
 * @internal
 */
@Pipe({
  name: 'toViewModel',
  standalone: true
})
export class ToViewModelPipe implements PipeTransform {
  transform(client: CRMClient): GuideClientDetailsModel {
    return new GuideClientDetailsModel(client);
  }
}
