import { inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { StripeJsV2Service } from '@app/core/stripe/stripe-js-v2.service';
import { Stripe } from '@stripe/stripe-js';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * @deprecated
 */
@Injectable({ providedIn: 'root' })
export class StripeJsService {
  private stripeJsV2 = inject(StripeJsV2Service);
  private _stripeSubject: ReplaySubject<Stripe> = new ReplaySubject<Stripe>(1);

  get stripe$() {
    return this._stripeSubject.asObservable();
  }

  constructor() {
    this.stripeJsV2.stripe$.pipe(takeUntilDestroyed()).subscribe(stripe => {
      this._stripeSubject.next(stripe);
    });
  }
}
