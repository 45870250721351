import { ChangeDetectionStrategy, Component, computed, Inject, input } from '@angular/core';
import { PUI_IS_MOBILE, PUI_IS_TABLET, PuiIconModule, PuiThumbnailComponent } from '@awarenow/profi-ui-core';
import { Observable } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { ServiceCardComponent } from '@app/components/service-card/service-card.component';

const icon = {
  laptop: {
    width: 80,
    height: 48
  },
  tablet: {
    width: 60,
    height: 38
  },
  mobile: {
    width: 44,
    height: 28
  }
};

interface Thumbnail {
  src: string | null;
  altIcon: string | null;
  width: number;
  height: number;
}

@Component({
  selector: 'offer-item-service',
  standalone: true,
  templateUrl: './offer-item-service.component.html',
  styleUrl: './offer-item-service.component.scss',
  imports: [ServiceCardComponent, PuiThumbnailComponent, PuiIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferItemServiceComponent {
  private isMobile = toSignal(this.isMobile$, { initialValue: false });
  private isTablet = toSignal(this.isTablet$, { initialValue: false });

  readonly title = input.required<string>();
  readonly subtitle = input.required<string>();
  readonly coverImageUrl = input.required<string | null>();
  readonly defaultIconName = input.required<string | null>();

  readonly thumbnail = computed<Thumbnail>(() => {
    const coverImageUrl = this.coverImageUrl();
    const defaultIconName = this.defaultIconName();
    const isMobile = this.isMobile();
    const isTablet = this.isTablet();

    const device = isMobile ? 'mobile' : isTablet ? 'tablet' : 'laptop';

    return {
      src: coverImageUrl,
      altIcon: defaultIconName ? 'pui:' + defaultIconName : 'pui:layers',
      width: icon[device].width,
      height: icon[device].height
    };
  });

  constructor(
    @Inject(PUI_IS_MOBILE) public isMobile$: Observable<boolean>,
    @Inject(PUI_IS_TABLET) private isTablet$: Observable<boolean>
  ) {}
}
