<pui-paper elevation="2" border="default" shape="rounded">
  <pui-card>
    <pui-card-header class="item">
      <div pui-card-header-icon>
        <!--      Icons-->
        <div class="item__icons pui-space_right-1">
          <ng-content select="[icons]" />
        </div>
      </div>

      <!--      Title-->
      <span pui-card-header-title pui-typography="body-s-bold">{{ title() | clearHtml }}</span>

      <div class="item__subheader" pui-card-header-subheader>
        <!--      Subtitle-->
        <small pui-typography="body-s">
          @if (isSubtitleTemplateRef()) {
            <ng-template *ngTemplateOutlet="subtitle()" />
          } @else {
            {{ subtitle() }}
          }
        </small>

        <ng-content select="[subheader]" />
      </div>

      <div class="item__actions" pui-card-header-action>
        <div class="item__actions-items">
          <!--      Actions-->
          <ng-content select="[action]" />
        </div>

        <!--      Context menu-->
        <ng-content select="[context-menu]" />
      </div>
    </pui-card-header>
  </pui-card>
</pui-paper>
