<pui-input-field [puiPrefix]="actions">
  <ng-template #actions>
    <pui-icon svgIcon="pui:search" size="xs"></pui-icon>
  </ng-template>
  <input
    pui-input-text
    size="s"
    type="text"
    data-qa-id="notes-filters-search-input"
    placeholder="Search"
    i18n-placeholder="@@search"
    [formControl]="searchControl" />
</pui-input-field>
<app-guide-relation-notes-board-toolbar
  [clientId]="clientId"
  [canShareNotes]="canShareNotes"></app-guide-relation-notes-board-toolbar>
