import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { PuiAvatarModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { profileCardDescription } from '@app/components/profile-card/profile-card-description.directive';
import { profileCardAvatar } from '@app/components/profile-card/profile-card-avatar.directive';
import { NgTemplateOutlet } from '@angular/common';
import { profileCardActions } from '@app/components/profile-card/profile-card-actions.directive';
import { profileCardOptions } from '@app/components/profile-card/profile-card-options.directive';
import { profileCardTypography } from './profile-card-typography';
import { Profile } from '@app/graphql/types';

@Component({
  selector: 'profile-card',
  host: {
    class: 'profile-card'
  },
  standalone: true,
  templateUrl: 'profile-card.component.html',
  styleUrl: 'profile-card.component.scss',
  imports: [PuiAvatarModule, PuiTypographyModule, NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileCardComponent {
  readonly profile = input.required<Profile>();

  readonly description = profileCardDescription();
  readonly avatar = profileCardAvatar();
  readonly actionsSignal = profileCardActions();

  readonly typography = profileCardTypography();

  readonly options = profileCardOptions();
}
