@let _profile = profile();
@let profilePhotoSize = avatar?.size() || 44;
@let titleTypography = options?.titleTypography() || 'body-s-bold';

<div class="profile-card__container">
  <pui-avatar
    class="profile-card__photo"
    zIndex="0"
    [avatarUrl]="_profile.photo"
    [text]="_profile.name"
    [size]="profilePhotoSize" />

  <div class="profile-card__content">
    <!--TITLE-->
    <div class="profile-card__title" [pui-typography]="titleTypography">{{ _profile.name }}</div>

    <!--SUBTITLE-->
    @if (description?.template(); as template) {
      <div class="profile-card__subtitle" pui-typography="body-s">
        <ng-template *ngTemplateOutlet="template; context: { profile: _profile }" />
      </div>
    }
  </div>

  @if (actionsSignal) {
    @let actions = actionsSignal();

    <div class="profile-card__actions">
      @for (action of actions; track action) {
        <ng-template *ngTemplateOutlet="action; context: { profile: _profile }" />
      }
    </div>
  }
</div>
