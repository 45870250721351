import type { WorkspaceGuidesState } from '@app/screens/guide/guide-clients/guide-client/store/workspace-guides-store/commons/types';
import { createReducer, on } from '@ngrx/store';
import {
  workspaceGuidesFetch,
  workspaceGuidesFetchSuccess,
  workspaceGuidesResetAll
} from './workspace-guides-store.actions';
import produce from 'immer';

const initialState: WorkspaceGuidesState = {
  status: 'initial',
  data: []
};

export const workspaceGuidesReducer = createReducer(
  initialState,
  on(workspaceGuidesResetAll, () => initialState),
  on(workspaceGuidesFetch, state =>
    produce(state, draft => {
      draft.status = draft.status === 'initial' ? 'loading' : 'updating';
    })
  ),
  on(workspaceGuidesFetchSuccess, (state, { data }) =>
    produce(state, draft => {
      draft.status = 'idle';
      draft.data = data;
    })
  )
);
