import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RedirectCommand,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { AUTH_ROUTE } from '@app/features/auth/auth.routes';

export const enrollmentUserGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (!authService.isAuthorized) {
    const redirectURL = new URL(state.url, location.origin);

    redirectURL.pathname += `/${AUTH_ROUTE}`;

    return new RedirectCommand(router.parseUrl(redirectURL.toString().replace(redirectURL.origin, '')));
  }

  return true;
};
