import { Directive, inject } from '@angular/core';
import { provideCurrentStep } from './services/current-step.service';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OrderStep } from '@app/graphql/types';
import { setCurrentStep } from '@app/features/enrollment/components/enrollment-steps/services/set-current.step';

@Directive({
  standalone: true,
  providers: [provideCurrentStep(OrderStep.YourInformation)]
})
export class EnrollmentCurrentStepBehavior {
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private setCurrentStep = setCurrentStep();

  private handleRouteStepChanged = this.router.events.pipe(
    map(() => {
      let step: OrderStep | null = null;
      let route = this.activatedRoute.snapshot;

      while (route) {
        if (route.data.step) {
          step = route.data.step;
        }

        route = route.firstChild!;
      }

      return step;
    }),
    filter(Boolean),
    distinctUntilChanged(),
    takeUntilDestroyed()
  );

  ngOnInit(): void {
    this.handleRouteStepChanged.subscribe(step => {
      this.setCurrentStep(step);
    });
  }
}
