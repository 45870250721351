import { computed, ElementRef, Injectable, signal } from '@angular/core';
import {
  loadStripe,
  Stripe,
  StripeCardCvcElement,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElement,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
  StripeElements
} from '@stripe/stripe-js';
import { StripeElementsNames } from './stripe-elements-names.enum';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StripeJsV2Service {
  private stripe: Stripe | null = null;
  private elements: StripeElements | null = null;
  private card: {
    cardNumber: StripeCardNumberElement;
    cardExpiry: StripeCardExpiryElement;
    cardCvc: StripeCardCvcElement;
  } | null = null;

  private isCardNumberValid = signal(false);
  private isCardExpiryValid = signal(false);
  private isCardCvcValid = signal(false);

  /**
   * @deprecated
   */
  readonly stripe$ = new Subject<Stripe>();

  readonly isNewPaymentMethodValid = computed(() => {
    return this.isCardNumberValid() && this.isCardExpiryValid() && this.isCardCvcValid();
  });

  async initializeStripeJs(publishKey: string): Promise<void> {
    if (!this.stripe) {
      try {
        this.stripe = await loadStripe(publishKey);
        this.elements = this.stripe?.elements() || null;
        this.stripe$.next(this.stripe!);
      } catch (error: unknown) {
        console.error('Load stripe error: ', JSON.stringify(error));
      }
    }
  }

  unloadStripe(): void {
    this.stripe = null;
    this.elements = null;
  }

  getStripe() {
    if (!this.stripe) {
      throw new Error('StripeJS is not initialized');
    }

    return this.stripe;
  }

  getElements() {
    if (!this.elements) {
      throw new Error('Stripe Elements is not initialized');
    }

    return this.elements;
  }

  mountPaymentMethod(elementRefs: Record<StripeElementsNames, ElementRef>) {
    this.card = {
      [StripeElementsNames.CARD_NUMBER]: this.mountStripeElement(
        StripeElementsNames.CARD_NUMBER,
        elementRefs[StripeElementsNames.CARD_NUMBER]
      ) as StripeCardNumberElement,
      [StripeElementsNames.CARD_EXPIRY]: this.mountStripeElement(
        StripeElementsNames.CARD_EXPIRY,
        elementRefs[StripeElementsNames.CARD_EXPIRY]
      ) as StripeCardExpiryElement,
      [StripeElementsNames.CARD_CVC]: this.mountStripeElement(
        StripeElementsNames.CARD_CVC,
        elementRefs[StripeElementsNames.CARD_CVC]
      ) as StripeCardCvcElement
    };
  }

  unMountPaymentMethod() {
    if (!this.card) {
      return;
    }

    this.card.cardNumber.off('change', this.onChangeCardNumber.bind(this));
    this.card.cardNumber.destroy();
    this.isCardNumberValid.set(false);

    this.card.cardExpiry.off('change', this.onChangeCardExpiry.bind(this));
    this.card.cardExpiry.destroy();
    this.isCardExpiryValid.set(false);

    this.card.cardCvc.off('change', this.onChangeCardCvc.bind(this));
    this.card.cardCvc.destroy();
    this.isCardCvcValid.set(false);
  }

  async createCardToken() {
    const result = await this.getStripe().createToken(this.card?.cardNumber!);
    if (result.error) {
      throw new Error(result.error.message);
    }

    return result.token.id;
  }

  async confirmCardPayment(clientSecret: string | null) {
    if (!clientSecret) {
      throw new Error('Client secret is not provided');
    }

    return this.getStripe().confirmCardPayment(clientSecret);
  }

  private mountStripeElement(
    type: StripeElementsNames,
    elementRef: ElementRef
  ): StripeCardNumberElement | StripeCardExpiryElement | StripeCardCvcElement {
    let element: StripeCardNumberElement | StripeCardExpiryElement | StripeCardCvcElement;

    switch (type) {
      case StripeElementsNames.CARD_NUMBER:
        element = this.getElements().create(StripeElementsNames.CARD_NUMBER, {
          placeholder: 'Card number'
        }) as StripeCardNumberElement;
        element.on('change', (event: StripeCardNumberElementChangeEvent) => this.onChangeCardNumber(event));
        break;
      case StripeElementsNames.CARD_EXPIRY:
        element = this.getElements().create(StripeElementsNames.CARD_EXPIRY) as StripeCardExpiryElement;
        element.on('change', (event: StripeCardExpiryElementChangeEvent) => this.onChangeCardExpiry(event));
        break;
      case StripeElementsNames.CARD_CVC:
        element = this.getElements().create(StripeElementsNames.CARD_CVC) as StripeCardCvcElement;
        element.on('change', (event: StripeCardCvcElementChangeEvent) => this.onChangeCardCvc(event));
        break;
      default:
        throw new Error('Unsupported element type');
    }

    element.mount(elementRef.nativeElement);

    return element;
  }

  private onChangeCardNumber(event: StripeCardNumberElementChangeEvent) {
    this.isCardNumberValid.set(event.complete);
  }

  private onChangeCardExpiry(event: StripeCardExpiryElementChangeEvent) {
    this.isCardExpiryValid.set(event.complete);
  }

  private onChangeCardCvc(event: StripeCardCvcElementChangeEvent) {
    this.isCardCvcValid.set(event.complete);
  }
}
