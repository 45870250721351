import type { Routes } from '@angular/router';
import { EnrollmentComponent } from './enrollment.component';
import yourInformationRoutes, { YOUR_INFORMATION_ROUTE } from './routes/your-information/your-information.routes';
import confirmationRoutes from './routes/confirmation/confirmation.routes';
import paymentRoutes from './routes/payment/payment.routes';
import { ENROLLMENT_OFFER_ITEM_ID_PARAM, ENROLLMENT_ROUTE } from './consts';

const routes: Routes = [
  {
    path: `${ENROLLMENT_ROUTE}/:${ENROLLMENT_OFFER_ITEM_ID_PARAM}`,
    component: EnrollmentComponent,
    children: [
      ...yourInformationRoutes,
      ...confirmationRoutes,
      ...paymentRoutes,
      {
        path: '**',
        redirectTo: YOUR_INFORMATION_ROUTE
      }
    ]
  }
];

export default routes;
