@let icon = thumbnail();

<service-card [title]="title()" [subtitle]="subtitle()">
  <!--      Icons-->
  <pui-thumbnail
    icons
    [width]="icon.width"
    [height]="icon.height"
    [altIcon]="icon.altIcon"
    [src]="icon.src"
    [altIconSize]="28" />

  <ng-container ngProjectAs="[action]">
    <ng-content select="[actions]" />
  </ng-container>

  <ng-container ngProjectAs="[context-menu]">
    <ng-content select="[menu]" />
  </ng-container>

  <ng-content />
</service-card>
