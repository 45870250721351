<div class="notes__container">
  <div class="notes__header pui-space_bottom-4">
    <div class="notes__header-title">
      <h1 pui-typography="h2" i18n="@@allNotes">All Notes</h1>
    </div>
    <div *ngIf="isMobile$ | async" class="notes__header-button">
      <button
        class="notes__button-close"
        pui-button
        type="button"
        aria-label="close"
        (click)="drawerRef.close()"
        appearance="flat">
        <pui-icon svgIcon="pui:x"></pui-icon>
      </button>
    </div>
  </div>
  <div class="notes__filters">
    <pui-input-field [puiPrefix]="actions">
      <ng-template #actions>
        <pui-icon svgIcon="pui:search" size="xs"></pui-icon>
      </ng-template>
      <input
        pui-input-text
        size="s"
        type="text"
        placeholder="Search"
        i18n-placeholder="@@search"
        [formControl]="searchControl" />
    </pui-input-field>
  </div>
  <!-- TODO Check if pui-overflow-auto-height required here. Having it on a container with ng-scrollbar led to endless changing of the height attribute (fixed in PR-6437) -->
  <div pui-overflow-auto-height class="notes__main">
    <ng-scrollbar (updated)="updateScroll($event)">
      <div class="notes-list">
        <app-note-card
          *ngFor="let note of notes$ | async; index as i"
          [noteIndex]="i"
          [note]="note"
          [hideShare]="true"
          (deleteNote)="onNoteDelete($event)"
          (updateNote)="onNoteUpdate($event, false)"
          (endEditing)="onNoteUpdate($event, true)"
          (pinNote)="onNotePin($event)"
          (unpinNote)="onNoteUnpin($event)">
          <app-client-note-card-meta class="meta" [note]="note"></app-client-note-card-meta>
        </app-note-card>
      </div>
    </ng-scrollbar>
  </div>
</div>
