import { inject, Injectable } from '@angular/core';
import { GuideClientCardTab } from '@app/screens/guide/guide-clients/guide-client/modules/guide-client-card/services/guide-client-card-tab';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  clientsByClientId,
  clientsByRelationId
} from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.selectors';
import {
  openClientDetails,
  openClientEngagements,
  openClientNotes,
  openClientHistory
} from '@app/features/client-card/services/open-client-card';

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root'
})
export class GuideClientCardService {
  private openClientDetails = openClientDetails();
  private openClientEngagements = openClientEngagements();
  private openClientNotes = openClientNotes();
  private openClientHistory = openClientHistory();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private readonly store$: Store
  ) {}

  /**
   * @deprecated
   */
  handleRouterChanges() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const { clientId, relationId, tab } = this.route.snapshot.queryParams;

      if (!relationId && !clientId) {
        return;
      }

      const selectFn = relationId ? clientsByRelationId : clientsByClientId;
      const id = relationId ? Number(relationId) : Number(clientId);

      this.store$
        .select(selectFn)
        .pipe(
          map(clients => clients[Number(id)]),
          filter(client => !!client),
          take(1)
        )
        // eslint-disable-next-line rxjs/no-nested-subscribe
        .subscribe(client => {
          this.open(client.id, tab || GuideClientCardTab.DETAILS);
        });
    });
  }

  /**
   * @deprecated
   */
  openDetails(id: CRMClient['id'], clientId?: CRMClient['clientId']) {
    return this.open(id, GuideClientCardTab.DETAILS, clientId);
  }

  /**
   * @deprecated
   */
  openNotes(id: CRMClient['id'], clientId?: CRMClient['clientId']) {
    return this.open(id, GuideClientCardTab.NOTES, clientId);
  }

  /**
   * @deprecated
   */
  openHistory(id: CRMClient['id']) {
    return this.open(id, GuideClientCardTab.HISTORY);
  }

  /**
   * @deprecated
   */
  private open(
    id: CRMClient['id'],
    tab: GuideClientCardTab = GuideClientCardTab.DETAILS,
    clientId?: CRMClient['clientId']
  ) {
    const param = { id, tab, clientId };

    switch (tab) {
      case GuideClientCardTab.ENGAGEMENTS:
        this.openClientEngagements(param);

        return;
      case GuideClientCardTab.HISTORY:
        this.openClientHistory(param);

        return;
      case GuideClientCardTab.NOTES:
        this.openClientNotes(param);

        return;
      case GuideClientCardTab.DETAILS:
      default:
        this.openClientDetails(param);

        return;
    }
  }
}
