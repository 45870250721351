import { inject, InjectionToken, Provider } from '@angular/core';
import { OfferItem } from '@app/graphql/types';
import { ActivatedRoute } from '@angular/router';
import { ENROLLMENT_OFFER_ITEM_ID_PARAM } from '@app/features/enrollment/consts';

const ENROLLMENT_OFFER_ITEM_ID = new InjectionToken<OfferItem['id']>('ENROLLMENT_OFFER_ITEM_ID');

export const provideEnrollmentOfferItemId = (): Provider[] => [
  {
    provide: ENROLLMENT_OFFER_ITEM_ID,
    useFactory: (route: ActivatedRoute) => route.snapshot.paramMap.get(ENROLLMENT_OFFER_ITEM_ID_PARAM),
    deps: [ActivatedRoute]
  }
];

export const enrollmentOfferItemId = () => inject(ENROLLMENT_OFFER_ITEM_ID);
