@let _profile = profile();

<profile-card
  [profile]="_profile"
  profileCardAvatar
  profileCardOptions
  profileCardTitleTypography="h3"
  [profileCardAvatarSize]="56"
  [profileCardActions]="[bookSessionAction, userCardActions]" />

<ng-template #bookSessionAction>
  <button pui-button appearance="secondary" size="s">
    <pui-icon svgIcon="pui:calendar" [size]="20" />
    Book a session
  </button>
</ng-template>

<ng-template #userCardActions>
  <button
    pui-icon-button
    appearance="secondary"
    size="s"
    pui-dropdown
    [pui-dropdown-content]="clientCardActions"
    aria-label="Show more actions">
    <pui-icon svgIcon="pui:more-horizontal" [size]="20" />
  </button>
</ng-template>

<ng-template #clientCardActions>
  <ul pui-list>
    @for (action of actions; track action) {
      <li pui-list-item="button" (click)="action.action()">
        <pui-icon [svgIcon]="action.icon" [size]="20" />
        {{ action.label }}
      </li>
    }
  </ul>
</ng-template>
