import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * @deprecated Use {(control) => Validator.required(control) && {required: 'Required field message.'}} instead
 */
export function customValidatorWrapper(validator: ValidatorFn, message: string): ValidatorFn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (control: AbstractControl): { [key: string]: any } => {
    const result = validator(control);

    // @ts-expect-error TS2322
    return result !== null ? { customValidatorWrapper: message } : result;
  };
}
