import { GuideClient, GuideContact } from '@app/core/users/types';
import { ClientView } from '@app/components/clients-select/type';
import { WorkspaceUser } from '@app/modules/workspaces/types';
import { normalizeFullName } from '@app/shared/utils/full-name';

export type ClientVariant = GuideClient | GuideContact | WorkspaceUser;

const isWorkspaceUser = (client: ClientVariant): client is WorkspaceUser => {
  return !('type' in client);
};

export const toClientView = (client: ClientVariant): ClientView => {
  if (client instanceof GuideClient || client instanceof GuideContact) {
    return {
      id: client.id.toString(),
      name: client.name,
      email: client.contacts?.email as string,
      photo: client.photo
    };
  }

  if (isWorkspaceUser(client)) {
    return {
      id: client.UserId.toString(),
      name: normalizeFullName(client) || '',
      email: client.email,
      photo: client.photo
    };
  }

  throw new Error('Unknown client interface.');
};
