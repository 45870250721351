import { Directive, inject } from '@angular/core';
import { orderId, provideOrderId } from './services/order-id';
import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ORDER_ID_PARAM } from './consts';

@Directive({
  standalone: true,
  providers: [provideOrderId()]
})
export class EnrollmentOrderIdBehavior {
  private activatedRoute = inject(ActivatedRoute);
  private orderId = orderId();
  private orderIdChange$ = this.activatedRoute.queryParamMap.pipe(
    map(params => params.get(ORDER_ID_PARAM)),
    filter(Boolean),
    distinctUntilChanged(),
    takeUntilDestroyed()
  );

  ngOnInit(): void {
    this.orderIdChange$.subscribe(orderId => {
      this.orderId.set(orderId);
    });
  }
}
