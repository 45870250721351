import { inject } from '@angular/core';
import { CreateOrderGQL } from '../__generated__/create-order.generated';
import { CreateOrderInput, OfferItem } from '@app/graphql/types';
import { filter, map } from 'rxjs/operators';

export const createOrder = () => {
  const gql = inject(CreateOrderGQL);

  return (offerItemId: OfferItem['id'], orderedHostId: CreateOrderInput['orderedHostId'] | null) =>
    gql.mutate({ input: { offerItemIds: [offerItemId], orderedHostId } }).pipe(
      filter(result => !result.loading),
      map(result => result.data!.createOrder)
    );
};
