import { NgModule } from '@angular/core';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { WorkspacesServerStoreService } from '@app/modules/workspaces/services/workspaces-server-store.service';
import { WORKSPACE_GUIDES_STORE } from './commons/consts';
import { workspaceGuidesReducer } from './workspace-guides-store.reducer';
import { WorkspaceGuidesStoreEffects } from './workspace-guides-store.effects';
import { workspaceGuidesFetch } from '@app/screens/guide/guide-clients/guide-client/store/workspace-guides-store/workspace-guides-store.actions';

@NgModule({
  imports: [
    StoreModule.forFeature(WORKSPACE_GUIDES_STORE, workspaceGuidesReducer),
    EffectsModule.forFeature([WorkspaceGuidesStoreEffects])
  ],
  providers: [WorkspacesServerStoreService]
})
export class WorkspaceGuidesStoreModule {
  constructor(private readonly store$: Store) {
    this.store$.dispatch(workspaceGuidesFetch());
  }
}
