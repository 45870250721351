@let config = brandingService.globalConfig$ | async;

@if (config.isFacebookAuthEnabled) {
  <div>
    <button
      pui-button
      appearance="secondary"
      class="w-full"
      (click)="thirdPartyAuthService.openFacebookAuthPopup(true)">
      <pui-icon svgIcon="pui:facebook-filled" />
      Continue with Facebook
    </button>
  </div>
}

@if (config.isGoogleAuthEnabled) {
  <div class="pui-space_top-4">
    <button pui-button appearance="secondary" class="w-full" (click)="thirdPartyAuthService.openGoogleAuthPopup(true)">
      <pui-icon svgIcon="pui:google" />
      Continue with Google
    </button>
  </div>
}
