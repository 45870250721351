@if (summaries$ | async; as summaries) {
  @let summary = summaries[selectedPaymentType];

  @if (!summary) {
    @defer {
      <payment-type [summaries]="summaries" (paymentTypeSelected)="setSelectedPaymentType($event)" />
    }
  } @else {
    @defer {
      <payment-summary [summary]="summary" />
    }
  }
}
