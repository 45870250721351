@let client = relation$ | async;

@if (client) {
  <app-notes-filters [searchControl]="searchControl" [canShareNotes]="canShareNotes(client)" [clientId]="client?.id" />

  <app-guide-relation-notes-board
    class="client-card-notes__main"
    [searchControl]="searchControl"
    [isAssigned]="isAssigned(client)"
    [guideRelation]="client" />
}
