<pui-drawer-content>
  <!-- HEADER -->
  <ng-template #header>
    <client-card-header />
  </ng-template>

  <!-- NAVIGATION -->
  <ng-template #navigation>
    <nav pui-tabs-bar>
      @for (tab of tabs; track tab) {
        <a
          pui-tabs-item
          routerLinkActive
          #routerLinkActive="routerLinkActive"
          queryParamsHandling="merge"
          [isActive]="routerLinkActive.isActive"
          [skipLocationChange]="true"
          [routerLink]="tab.path">
          {{ tab.label }}
        </a>
      }
    </nav>
  </ng-template>

  <!-- MAIN -->
  <ng-template #main>
    <router-outlet name="clientCard" />
  </ng-template>
</pui-drawer-content>
