import { ChangeDetectionStrategy, Component, computed, input, TemplateRef } from '@angular/core';
import { OfferItemType } from '@app/graphql/types';
import { PuiCardModule, PuiIconModule, PuiPaperModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { ClearHtmlPipe } from '@libs/shared/pipes/clear-html.pipe';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'service-card',
  standalone: true,
  templateUrl: './service-card.component.html',
  styleUrl: './service-card.component.scss',
  imports: [PuiCardModule, PuiTypographyModule, ClearHtmlPipe, PuiIconModule, PuiPaperModule, NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceCardComponent {
  protected OfferItemType = OfferItemType;

  readonly title = input.required<string>();
  readonly subtitle = input.required<string | TemplateRef<unknown>>();

  readonly isSubtitleTemplateRef = computed(() => this.subtitle() instanceof TemplateRef);
}
