import { CookieService } from '@libs/cookie-service/cookie.service';

import { CommonModule, JsonPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BookQuizService } from '@app/core/book-quiz/book-quiz.service';
import { ExternalCalendarsApiService } from '@app/core/calendars/external-calendars-api.service';
import { ExternalCalendarsService } from '@app/core/calendars/external-calendars.service';
import { CheckTokenInterceptor } from '@app/core/http/check-token-http.interceptor';

import { AnalyticsService } from './analytics/analytics.service';
import { AuthService } from './auth/services/auth.service';
import { RecoverService } from './auth/services/recover.service';
import { WINDOW_PROVIDERS } from './browser-window';
import { ChatsBotsService } from './chat/chats-bots.service';
import { ChatsFilesUploaderService } from './chat/chats-files-uploader.service';
import { ChatsSocketService } from './chat/chats-socket.service';
import { ChatsStoreService } from './chat/chats-store.service';
import { ChatsUsersMapService } from './chat/chats-users-map.service';
import { ChatsUsersService } from './chat/chats-users.service';
import { ChatsService } from './chat/chats.service';
import { GroupChatUsersService } from './chat/group-chat-users.service';
import { CookieCollectionService } from './cookie-collection/cookie-collection.service';
import { EmailAlertsService } from './email/email-alerts.service';
import { ErrorService } from './error/error.service';
import { FirstpromoterService } from './firstpromoter/firstpromoter.service';
import { FormService } from './form/form.service';
import { GuideProfileNotificationsService } from './guide-profile/guide-profile-notifications.service';
import { OurHttpInterceptor } from './http/our-http-interceptor';
import { LocalStorageService } from './local-storage/local-storage.service';
import { LogService } from './log/log.service';
import { MembershipService } from './membership/membership.service';
import { SystemNotificationsService } from './notifications/system-notifications.service';
import { PaymentsService } from './payments/payments.service';
import { PaypalPaymentService } from './paypal/paypal-payment.service';
import { PostPopupWindowService } from './paypal/post-popup-window-service';
import { PreloaderService } from './preloader/preloader.service';
import { FindYourGuideService } from './public/find-your-guide.service';
import { GuidePublicServicesService } from './public/guide-public-services.service';
import { GuidesWikiService } from './public/guides-wiki.service';
import { HelpMeFindAGuideCommunicatorService } from './public/help-me-find-a-guide-communicator.service';
import { ProfileService } from './public/profile.service';
import { SearchStringService } from './public/search-string.service';
import { QuizService } from './quizzes/quiz.service';
import { GuideScheduleEndpointService } from './schedule/guide-schedule-endpoint.service';
import { ServiceScheduleApiService } from './schedule/service-schedule-api.service';
import { ServiceScheduleService } from './schedule/service-schedule.service';
import { SESSION_STORAGE_PROVIDERS } from './session-storage';
import { GuideOfferApiService } from './session/guide-offer-api.service';
import { SessionsApiService } from './session/sessions-api.service';
import { SessionsEndpointResolverService } from './session/sessions-endpoint-resolver.service';
import { SessionsService } from './session/sessions.service';
import { SharedEventActionService } from './shared-event-actions/shared-event-action.service';
import { SharedEventActionsMediatorService } from './shared-event-actions/shared-event-actions-mediator.service';
import { SimpleIdProvider } from './simple-id';
import { FacebookService } from './social-services/facebook.service';
import { TwitterService } from './social-services/twitter.service';
import { SocketService } from './socket/socket.service';
import { SoundService } from './sound/sound.service';
import { SsrCookieService } from './ssr-cookie/ssr-cookie.service';
import { OnlineStatusService } from './status/online-status.service';
import { SubscriptionsService } from './subscriptions/subscriptions.service';
import { SystemTimeService } from './system-time/system-time.service';
import { TimezonesService } from './timezones/timezones.service';
import { UploaderService } from './uploader/uploader.service';
import { UserWalletNotificationsService } from './user-wallet/user-wallet-notifications.service';
import { UserWalletService } from './user-wallet/user-wallet.service';
import { ClientGuidesService } from './users/client-guides.service';
import { GuideClientsService } from './users/guide-clients.service';
import { WindowService } from './window/window.service';
import { ZapierService } from './zapier/zapier.service';
import { ZoomService } from './zoom/zoom.service';
import { RecordingsService } from '@app/core/public/recordings.service';
import { ConnectionTypeService } from '@app/core/connection-type/connection-type.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    AnalyticsService,
    AuthService,
    BookQuizService,
    ChatsService,
    ChatsFilesUploaderService,
    ChatsSocketService,
    ChatsStoreService,
    ChatsUsersMapService,
    ChatsUsersService,
    ClientGuidesService,
    CookieService,
    EmailAlertsService,
    ErrorService,
    ExternalCalendarsApiService,
    ExternalCalendarsService,
    FacebookService,
    FindYourGuideService,
    FirstpromoterService,
    FormService,
    GroupChatUsersService,
    GuideClientsService,
    GuideOfferApiService,
    GuideProfileNotificationsService,
    GuideScheduleEndpointService,
    GuidesWikiService,
    HelpMeFindAGuideCommunicatorService,
    LocalStorageService,
    LogService,
    OnlineStatusService,
    PaypalPaymentService,
    PostPopupWindowService,
    PreloaderService,
    ProfileService,
    QuizService,
    RecoverService,
    GuidePublicServicesService,
    SearchStringService,
    ServiceScheduleApiService,
    ServiceScheduleService,
    SessionsService,
    SessionsApiService,
    SessionsEndpointResolverService,
    SharedEventActionService,
    SharedEventActionsMediatorService,
    SimpleIdProvider,
    SocketService,
    SoundService,
    SsrCookieService,
    SystemNotificationsService,
    SystemTimeService,
    RecordingsService,
    TwitterService,
    WindowService,
    UploaderService,
    UserWalletNotificationsService,
    UserWalletService,
    CookieCollectionService,
    ChatsBotsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CheckTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OurHttpInterceptor,
      multi: true
    },
    SESSION_STORAGE_PROVIDERS,
    TimezonesService,
    WINDOW_PROVIDERS,
    ZoomService,
    ZapierService,
    MembershipService,
    SubscriptionsService,
    PaymentsService,
    ConnectionTypeService,
    JsonPipe,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
