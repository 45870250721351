import { OrderStep } from '@app/graphql/types';
import { currentStep } from '@app/features/enrollment/components/enrollment-steps/services/current-step.service';

export const setCurrentStep = () => {
  const _currentStep = currentStep();

  return (step: OrderStep) => {
    _currentStep.set(step);
  };
};
