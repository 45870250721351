import { NgModule } from '@angular/core';
import { WorkspaceGuidesStoreModule } from '@app/screens/guide/guide-clients/guide-client/store/workspace-guides-store/workspace-guides-store.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ClientAssignedGuidesStoreEffects } from './client-assigned-guides-store.effects';
import Reducer from './client-assigned-guides-store.reducer';
import { CLIENTS_ASSIGNED_GUIDES_STORE } from './commons/consts';
import { ClientAssignedMemberApiService } from '@app/features/client-card/routes/client-details/components/assigned-team-memeber-selector/services/client-assigned-member-api.service';

@NgModule({
  imports: [
    StoreModule.forFeature(CLIENTS_ASSIGNED_GUIDES_STORE, Reducer),
    EffectsModule.forFeature([ClientAssignedGuidesStoreEffects]),
    WorkspaceGuidesStoreModule
  ],
  providers: [ClientAssignedMemberApiService]
})
export class ClientAssignedGuidesStoreModule {}
