import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { PuiButtonModule, PuiIconModule, PuiLinkModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { orderId } from '../../services/order-id';
import { map } from 'rxjs/operators';
import { GetConfirmationDetailsGQL } from './__generated__/get-confirmation-details.generated';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { OfferItemServiceComponent } from '@app/features/offers/components/offer-item-service/offer-item-service.component';
import { useEnrollmentOfferItem } from '../../services/enrollment-offer-item.query-ref';
import { OfferItemType } from '@app/graphql/types';
import { NgPlural, NgPluralCase } from '@angular/common';

@Component({
  selector: 'confirmation',
  templateUrl: 'confirmation.component.html',
  styleUrls: ['confirmation.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PuiIconModule,
    PuiTypographyModule,
    PuiLinkModule,
    PuiButtonModule,
    AsyncPipe,
    OfferItemServiceComponent,
    TitleCasePipe,
    NgPlural,
    NgPluralCase
  ]
})
export class ConfirmationComponent {
  private orderId = orderId();

  readonly order$ = inject(GetConfirmationDetailsGQL)
    .fetch({ orderId: this.orderId()! })
    .pipe(map(result => result.data?.getOrder));

  private enrollmentOfferItem$ = useEnrollmentOfferItem();

  protected readonly OfferItemType = OfferItemType;
}
