import type {
  Guide,
  CRMClient
} from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from '@app/core';
import { Injectable } from '@angular/core';
import type { IWorkspaceMember } from '@app/modules/workspaces/types';

@Injectable()
/**
 * @internal
 */
export class ClientAssignedMemberApiService {
  constructor(private readonly httpClient: HttpClient) {}

  getAssignedGuidesByClientId(id: CRMClient['id']): Observable<{ assignedMembers: IWorkspaceMember[] }> {
    return this.httpClient.get<{ assignedMembers: IWorkspaceMember[] }>(
      `${config.apiPath}/user/guide/crm/assigned-members/${id}`
    );
  }

  assignGuides(id: CRMClient['id'], guideIds: Guide['userId'][]): Observable<unknown> {
    return this.httpClient.post<unknown>(`${config.apiPath}/user/guide/relations/clients/${id}/guides`, {
      guideIds
    });
  }
}
