import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { clientsHistoryReducer } from './clients-history.reducer';
import { ClientsHistoryEffects } from './clients-history.effects';
import { GuideClientsStoreModule } from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.module';

@NgModule({
  imports: [
    StoreModule.forFeature('clientsHistory', clientsHistoryReducer),
    EffectsModule.forFeature([ClientsHistoryEffects]),
    GuideClientsStoreModule
  ]
})
export class GuideClientHistoryStoreModule {}
