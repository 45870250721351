import { AUTH_ROUTE } from '@app/features/auth/auth.routes';
import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export const changeAccount = () => {
  const router = inject(Router);
  const route = inject(ActivatedRoute);

  return () =>
    router.navigate([AUTH_ROUTE], {
      relativeTo: route,
      queryParamsHandling: 'merge'
    });
};
