import { map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { WorkspacesServerStoreService } from '@app/modules/workspaces/services/workspaces-server-store.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { workspaceGuidesFetch, workspaceGuidesFetchSuccess } from './workspace-guides-store.actions';

@Injectable()
export class WorkspaceGuidesStoreEffects {
  readonly fetchAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workspaceGuidesFetch),
      switchMap(() =>
        this.workspaceApiService.getMembers$().pipe(
          map(data => ({
            type: workspaceGuidesFetchSuccess.type,
            data
          }))
        )
      )
    )
  );

  constructor(private readonly actions$: Actions, private readonly workspaceApiService: WorkspacesServerStoreService) {}
}
