import { Routes } from '@angular/router';
import { OrderStep } from '@app/graphql/types';
import { ConfirmationComponent } from './confirmation.component';

export const CONFIRMATION_ROUTE = 'confirmation';

const routes: Routes = [
  {
    path: CONFIRMATION_ROUTE,
    data: { step: OrderStep.Confirmation },
    component: ConfirmationComponent
  }
];

export default routes;
