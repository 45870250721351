import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { type ApolloClientOptions, InMemoryCache, split } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '@env/environment';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { Kind } from 'graphql';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import extractFiles from 'extract-files/extractFiles.mjs';
import { AuthService } from '@app/core/auth/services';
import { lastValueFrom } from 'rxjs';
import { typePolicies } from '@app/core/gql/policies/type-policies';

const uri = environment.graphqlHost;

export function createApollo(httpLink: HttpLink, authService: AuthService): ApolloClientOptions<unknown> {
  const http = httpLink.create({
    uri,
    withCredentials: true,
    extractFiles
  });

  const ws = new GraphQLWsLink(
    createClient({
      url: uri?.replace(/^(http:\/\/)|(https:\/\/)/, match => (match === 'http://' ? 'ws://' : 'wss://'))!,
      shouldRetry: () => true,
      connectionParams: async () => {
        let token = authService.getTokenFromCookie();

        if (!token) {
          await lastValueFrom(authService.onAuth());
          token = authService.getTokenFromCookie();
        }
        return {
          token
        };
      }
    })
  );

  const link = split(
    // Split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === 'subscription';
    },
    ws,
    http
  );

  return {
    link,
    cache: new InMemoryCache(typePolicies)
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService]
    }
  ]
})
export class GraphQLModule {}
