<div class="chat-list__container">
  <div class="pui-padding_x-6 pui-padding_top-6">
    <h2 pui-typography="h2" transloco="chatListCompHeaderTitle" data-qa-id="chat-list-secure-messaging-title">
      Secure messaging
    </h2>
  </div>
  <div class="pui-space_top-6 pui-padding_x-6">
    <pui-input-field [puiPrefix]="actions">
      <ng-template #actions>
        <pui-icon svgIcon="pui:search" [size]="20" />
      </ng-template>
      <input
        pui-input-text
        type="text"
        placeholder="Search chats"
        [(ngModel)]="searchText"
        i18n-placeholder="@@ChatsListCompSearchInputPlaceholder"
        data-qa-id="chat-list-search-input" />
    </pui-input-field>
  </div>

  <div class="chat-list__content pui-space_top-5">
    <div puiScrollable cdkScrollable class="pui-scroll scrollable">
      <div class="scrollable__container">
        @for (chat of chats | filterChatList: searchText; track chat.id) {
          <chat-item
            (click)="emitSelectChat(chat)"
            [avatar]="chat.photo"
            [alt]="chat.name"
            [title]="chat.name"
            [subtitle]="chat.lastMessage"
            [unread]="chat.notificationsCount"
            [date]="chat.lastMessageDate"
            [archived]="chat.archived"
            [selected]="chat.id === selectedChatId" />
        } @empty {
          <div class="pui-padding_x-6">
            <span i18n="@@chatsListEmptyState" class="no-active-chats">No active chats</span>
          </div>
        }
      </div>
    </div>
  </div>
</div>
