<div class="container">
  <span pui-typography="h3" [pui-tooltip]="session?.template.name">
    {{ session?.template.name }}
  </span>

  <div class="details" pui-typography="alert">
    <div class="session-client-avatar item">
      <pui-avatar-group *ngIf="sessionHasMultipleClients; else sessionOneToOneTemplate" tabindex="0">
        <pui-avatar
          size="m"
          *ngFor="let item of this.session.clients.slice(0, MAX_AVATARS)"
          [avatarUrl]="item.photo"
          [text]="item | userName"></pui-avatar>
        <ng-container *ngIf="this.session.clients.length - MAX_AVATARS > 0" ngProjectAs="pui-avatar">
          <pui-avatar
            size="m"
            [text]="'+' + (this.session.clients.length - MAX_AVATARS)"
            [pui-dropdown-content]="avatarMenu"
            [items]="this.session.clients"
            pui-dropdown=""
            role="button"
            aria-pressed="false"></pui-avatar>
        </ng-container>
      </pui-avatar-group>
    </div>
    <div class="session-duration item">
      <pui-icon svgIcon="pui:clock" size="s" aria-hidden="true" class="icon"></pui-icon>
      <span class="duration-value">
        {{ session?.duration }}
        <span i18n="@@sessionDurationUnit">min</span>
      </span>
    </div>
    <div class="session-location item">
      <pui-icon
        [svgIcon]="session?.connectionType | sessionConnectionTypeIcon"
        size="s"
        aria-hidden="true"
        class="icon"></pui-icon>
      {{ session?.template.connectionType | sessionPlatform: session?.template.address }}
    </div>
  </div>
</div>

<ng-template #avatarMenu let-items="items">
  <ul pui-list>
    <li *ngFor="let item of items" pui-list-item>
      <pui-card>
        <pui-card-header>
          <div pui-card-header-icon>
            <pui-avatar [text]="item | userName" [avatarUrl]="item.photo"></pui-avatar>
          </div>
          <div pui-card-header-title>
            <span>{{ item | userName }}</span>
          </div>
        </pui-card-header>
      </pui-card>
    </li>
  </ul>
</ng-template>

<ng-template #sessionOneToOneTemplate>
  <pui-avatar
    class="user-photo"
    [text]="session.client | userName"
    [avatarUrl]="session.client.photo"
    size="m"></pui-avatar>
  <span>{{ session.client | userName }}</span>
</ng-template>
