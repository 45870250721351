@let _form = form();

<form [formGroup]="_form" class="pui-form" (ngSubmit)="submit()">
  <div class="pui-form__row">
    <!-- EMAIL -->
    <div class="pui-form__field">
      <label pui-input-label for="email"> Email </label>
      <pui-input-field>
        <input
          id="email"
          formControlName="email"
          pui-input-text
          type="email"
          placeholder="example@mail.com"
          [readonly]="true" />
      </pui-input-field>
    </div>
  </div>

  <div class="pui-form__row">
    <!-- PASSWORD -->
    <div class="pui-form__field">
      <label pui-input-label for="password"> Password </label>
      <pui-input-field>
        <input id="password" formControlName="password" pui-input-text type="password" />
      </pui-input-field>
    </div>
  </div>

  <div class="pui-form__row">
    <!-- FIRST NAME -->
    <div class="pui-form__field">
      <label pui-input-label for="firstName"> First name </label>
      <pui-input-field>
        <input id="firstName" formControlName="firstName" pui-input-text type="text" />
      </pui-input-field>
    </div>
  </div>

  <div class="pui-form__row">
    <!-- LAST NAME -->
    <div class="pui-form__field">
      <label pui-input-label for="lastName"> Last name </label>
      <pui-input-field>
        <input id="lastName" formControlName="lastName" pui-input-text type="text" />
      </pui-input-field>
    </div>
  </div>

  <div class="pui-form__row">
    <!-- AGREE WITH TERMS -->
    <div class="pui-form__field">
      <label formControlName="agreeWithTerms" pui-input-checkbox pui-typography="body-s">
        I authorize the processing of personal data and agree with <a pui-link>Terms&Conditions</a> and
        <a pui-link>Privacy Policy</a>
      </label>
    </div>
  </div>

  <div class="pui-form__buttons text-center">
    <button pui-button type="submit">Create account</button>
  </div>
</form>
