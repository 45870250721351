import { DestroyRef, Directive, inject, InjectionToken, signal, WritableSignal } from '@angular/core';

const defaultContentWidth = '490px';
const ENROLLMENT_CONTENT_WIDTH = new InjectionToken<WritableSignal<string>>('ENROLLMENT_CONTENT_WIDTH');

@Directive({
  standalone: true,
  providers: [
    {
      provide: ENROLLMENT_CONTENT_WIDTH,
      useValue: signal<string>(defaultContentWidth)
    }
  ],
  host: {
    '[style.--enrollment-content-width]': 'contentWidth()'
  }
})
export class EnrollmentContentWidthBehavior {
  protected contentWidth = inject(ENROLLMENT_CONTENT_WIDTH);
}

export const setEnrollmentContentWidth = () => {
  const contentWidth = inject(ENROLLMENT_CONTENT_WIDTH);
  const destroyRef = inject(DestroyRef);

  const unregisterFn = destroyRef.onDestroy(() => {
    contentWidth.set(defaultContentWidth);
    unregisterFn();
  });

  return (width: string) => {
    contentWidth.set(width);
  };
};
