import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { checkUserExist } from '@app/core/auth/services';
import {
  PuiButtonModule,
  PuiDividerModule,
  PuiIconModule,
  PuiInputCheckboxModule,
  PuiInputModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { lastValueFrom } from 'rxjs';
import { goToSignIn } from '@app/features/auth/services/go-to-sign-in';
import { goToSignUp } from '@app/features/auth/services/go-to-sign-up';
import { ThirdPartyAuthComponent } from '@app/features/auth/components/third-party-auth/third-party-auth.component';

export type CheckUserExistResult = { email: string; isUserExist: boolean };

@Component({
  selector: 'check-user-exist-form',
  templateUrl: './check-user-exist-form.component.html',
  styleUrl: 'check-user-exist-form.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    PuiButtonModule,
    PuiInputModule,
    PuiInputCheckboxModule,
    PuiTypographyModule,
    PuiIconModule,
    ThirdPartyAuthComponent,
    PuiDividerModule
  ]
})
export class CheckUserExistFormComponent {
  protected form = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    remember: new FormControl(true)
  });

  private checkUserExist = checkUserExist();
  private goToSignIn = goToSignIn();
  private goToSignUp = goToSignUp();

  async submit() {
    const emailControl = this.form.controls.email;
    const isEmailValid = !emailControl.errors?.email;

    if (!isEmailValid) {
      return;
    }

    const email = emailControl.value!;
    const isUserExist = await lastValueFrom(this.checkUserExist(email));

    isUserExist ? await this.goToSignIn(email) : await this.goToSignUp(email);
  }
}
