import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { EnrollmentStepsComponent } from './components/enrollment-steps/enrollment-steps.component';
import { EnrollmentCurrentStepBehavior } from './components/enrollment-steps/enrollment-current-step.behavior';
import { EnrollmentNavigationService } from './services/enrollment-navigation.service';
import { EnrollmentContentWidthBehavior } from './enrollment-content-width.behavior';
import { EnrollmentOrderIdBehavior } from './enrollment-order-id.behavior';
import { provideEnrollmentOfferItemId } from '@app/features/enrollment/services/enrollment-offer-item-id';
import { provideEnrollmentOfferItem } from './services/enrollment-offer-item.query-ref';

@Component({
  selector: 'enrollment',
  templateUrl: 'enrollment.component.html',
  standalone: true,
  styleUrl: 'enrollment.component.scss',
  hostDirectives: [EnrollmentCurrentStepBehavior, EnrollmentOrderIdBehavior, EnrollmentContentWidthBehavior],
  providers: [EnrollmentNavigationService, provideEnrollmentOfferItemId(), provideEnrollmentOfferItem()],
  imports: [RouterOutlet, EnrollmentStepsComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnrollmentComponent {}
