import { Directive, inject, PLATFORM_ID } from '@angular/core';
import { AuthService } from '@app/core/auth/services';
import { StripeJsV2Service } from '@app/core/stripe/stripe-js-v2.service';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[stripeJsInitializationBehavior]',
  standalone: true
})
export class StripeJsInitializationBehavior {
  private auth = inject(AuthService);
  private stripeService = inject(StripeJsV2Service);
  private runtimeConfig = inject(RuntimeConfigService);
  private platformId = inject(PLATFORM_ID);

  constructor() {
    this.auth
      .onAuth()
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        const publishKey = this.runtimeConfig.get('STRIPE_PUBLISH_KEY');
        const isStripeEnabled = !!this.runtimeConfig.get('stripeEnabled');

        if (!isPlatformBrowser(this.platformId)) {
          return;
        }

        if (!isStripeEnabled) {
          return;
        }

        if (!publishKey) {
          console.warn(`STRIPE_PUBLISH_KEY is missing.`);
          return;
        }

        return this.stripeService.initializeStripeJs(publishKey);
      });

    this.auth
      .onLogout()
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.stripeService.unloadStripe();
      });
  }
}
