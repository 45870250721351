import { Routes } from '@angular/router';
import {
  OFFER_HOST_ID_ROUTE,
  OFFER_HOST_ROUTE,
  OFFER_ID_ROUTE,
  OFFER_ITEM_BUNDLE_ROUTE,
  OFFER_ITEM_ENGAGEMENT_ROUTE,
  OFFER_ITEM_ID_ROUTE,
  OFFER_ITEM_ROUTE,
  OFFER_ITEM_SESSION_ROUTE,
  OFFER_SERVICE_ENGAGEMENT_ROUTE,
  OFFER_SERVICE_ID_ROUTE,
  OFFER_SERVICE_ROUTE,
  OFFERS_ROUTE
} from '@app/features/offers/constants/constants';

const offersLandingRoutes: Routes = [
  // Offer landing page
  {
    path: `${OFFERS_ROUTE}/:${OFFER_ID_ROUTE}`,
    loadChildren: () => import('@app/features/offers/pages/offer-landing-page/offer-landing-page.routes')
  },
  {
    path: `${OFFERS_ROUTE}/:${OFFER_ID_ROUTE}/${OFFER_ITEM_ROUTE}/:${OFFER_ITEM_ID_ROUTE}`,
    children: [
      // Bundle landing page
      {
        path: OFFER_ITEM_BUNDLE_ROUTE,
        pathMatch: 'full',
        loadChildren: () =>
          import('@app/features/offers/pages/offer-item-bundle-landing-page/offer-bundle-landing-page.routes')
      },
      // Session landing page
      {
        path: OFFER_ITEM_SESSION_ROUTE,
        pathMatch: 'full',
        loadChildren: () =>
          import('@app/features/offers/pages/offer-item-session-landing-page/offer-item-session-landing-page.routes')
      },
      // Engagement landing page
      {
        path: OFFER_ITEM_ENGAGEMENT_ROUTE,
        pathMatch: 'full',
        loadChildren: () =>
          import(
            '@app/features/offers/pages/offer-item-engagement-landing-page/offer-item-engagement-landing-page.routes'
          )
      },
      // Service engagement landing page
      {
        path: `${OFFER_SERVICE_ROUTE}/:${OFFER_SERVICE_ID_ROUTE}/${OFFER_SERVICE_ENGAGEMENT_ROUTE}`,
        pathMatch: 'full',
        loadChildren: () =>
          import(
            '@app/features/offers/pages/offer-service-engagement-landing-page/offer-service-engagement-landing-page.routes'
          )
      }
    ]
  },
  // Offer host landing page
  {
    path: `${OFFERS_ROUTE}/:${OFFER_ID_ROUTE}/${OFFER_HOST_ROUTE}/:${OFFER_HOST_ID_ROUTE}`,
    loadChildren: () => import('@app/features/offers/pages/offer-host-landing-page/offer-host-landing-page.routes')
  }
];

export default offersLandingRoutes;
