<h3 pui-typography="h3" class="text-center">Sign in to continue</h3>

<div class="pui-space_top-5">
  <third-party-auth />
</div>

<div class="pui-space_bottom-6 pui-space_top-6 divider" aria-hidden="true">
  <pui-divider></pui-divider>
  <i class="pui-padding_left-2 pui-padding_right-2" pui-typography="body-s">or</i>
  <pui-divider></pui-divider>
</div>

<form [formGroup]="form" class="pui-form" (ngSubmit)="submit()">
  <div class="pui-form__row">
    <!-- EMAIL -->
    <div class="pui-form__field">
      <label pui-input-label for="email"> Enter your email </label>
      <pui-input-field>
        <input
          id="email"
          formControlName="email"
          pui-input-text
          type="email"
          class="email-input"
          placeholder="example@mail.com" />
      </pui-input-field>
    </div>
  </div>

  <div class="pui-form__row">
    <!-- REMEMBER USER -->
    <div class="pui-form__field">
      <label formControlName="remember" pui-input-checkbox pui-typography="body-s">Remember me on this computer.</label>
    </div>
  </div>

  <div class="pui-form__buttons">
    <button pui-button type="submit" class="w-full">
      Continue
      <pui-icon svgIcon="pui:chevron-right" [size]="20" />
    </button>
  </div>
</form>
