<ng-container *ngIf="(filteredServices$ | async)?.length">
  <div
    *ngIf="appearance.header"
    class="group-services__header pui-space_bottom-4"
    (click)="expanded$.next(!expanded$.value)">
    <pui-icon
      class="pui-space_right-3"
      size="s"
      [svgIcon]="expanded$.value ? 'pui:chevron-down' : 'pui:chevron-right'"></pui-icon>

    <h4 pui-typography="h4">{{ headerText }}</h4>
  </div>
  <div *ngIf="expanded$.value" class="group-services__body">
    <div
      class="group-services__item"
      [attr.data-qa-id]="'propose-service__service-' + service?.name"
      *ngFor="let service of filteredServices$ | async; index as index; trackBy: trackServiceById">
      <ng-container>
        <div class="d-flex flex-column w-100" (click)="selectService.emit(service); selectServiceByIndex.emit(index)">
          <div class="group-services__item-title">
            <app-guide-service-badge
              *ngIf="appearance.badge"
              class="pui-space_right-2"
              [type]="service.type"></app-guide-service-badge>

            <span pui-typography="body-s-bold">
              <ng-container *ngIf="service.count > 1">{{ service.count }} x</ng-container>
              {{ service?.name }}
            </span>
          </div>
          <div class="group-services__info">
            <div class="group-services__barges">
              <!-- Parent Service -->
              <div class="group-services__barge-wrapper" *ngIf="service?.parentService">
                <div class="square" [ngClass]="service?.parentService.type"></div>
                <span>{{ service?.parentService.name }}</span>
              </div>
              <!-- Sessions -->
              <div *ngIf="service?.sessions?.length > 0">
                <ng-container *ngTemplateOutlet="sessionsTemplate; context: { $implicit: service }"></ng-container>
              </div>
              <!-- Duration -->
              <div *ngIf="service?.duration" class="group-services__barge-wrapper">
                <pui-icon class="pui-space_right-1" size="xs" svgIcon="pui:clock"></pui-icon>
                <span> {{ service?.duration }}m</span>
              </div>
              <!-- Price -->
              <ng-container *ngIf="!service?.hidePrice && !service?.parentService">
                <ng-container *ngTemplateOutlet="priceTemplate; context: { $implicit: service }"></ng-container>
              </ng-container>
              <!-- Seats -->
              <div *ngIf="service?.seatsPerTimeSlot" class="group-services__barge-wrapper">
                <pui-icon class="pui-space_right-1" size="xs" svgIcon="pui:user"></pui-icon>
                <span> {{ service?.seatsPerTimeSlot }}</span>
              </div>
              <!-- Session Type -->
              <div *ngIf="service?.sessionType" class="group-services__barge-wrapper">
                <app-session-type iconSize="xs" [type]="service.sessionType"></app-session-type>
              </div>
              <!-- Location -->
              <div *ngIf="service?.connectionType" class="group-services__barge-wrapper">
                <ng-container *ngTemplateOutlet="locationTemplate; context: { $implicit: service }"></ng-container>
              </div>
              <!-- Starts Date -->
              <ng-container *ngIf="service?.startDate">
                <ng-container *ngTemplateOutlet="startsDateTemplate; context: { $implicit: service }"></ng-container>
              </ng-container>
              <!-- Recurrence -->
              <ng-container *ngIf="service?.recurring?.count > 1">
                <div class="group-services__barge-wrapper" *ngIf="service?.recurring?.count as recurringCount">
                  <pui-icon class="pui-space_right-1" svgIcon="pui:repeat" size="xs" aria-hidden="true"></pui-icon>
                  <ng-container i18n="@@guideSessionRepeatsUpTo">Repeats up to</ng-container>
                  {{ recurringCount }}
                  <ng-container i18n="@@guideSessionRepeatsTimes">times</ng-container>
                </div>
              </ng-container>
            </div>
            <!-- Team -->
            <ng-container *ngIf="service?.team">
              <ng-container *puiLet="service?.team | skipOwner as guides">
                <pui-avatar-group size="s">
                  <pui-avatar
                    *ngFor="let guide of guides?.slice(0, MAX_GUIDE_AVATARS)"
                    size="s"
                    [avatarUrl]="guide?.photo"
                    [text]="guide?.fullname || guide?.username"></pui-avatar>
                  <ng-container *ngIf="guides?.length - MAX_GUIDE_AVATARS > 0" ngProjectAs="pui-avatar">
                    <pui-avatar
                      *ngIf="service.sessionType !== SESSION_TYPE.ROUND_ROBIN"
                      aria-hidden="true"
                      pui-dropdown
                      (click)="$event.stopPropagation()"
                      [pui-dropdown-content]="assignedGuidesDropdown"
                      [pui-dropdown-min-width]="240"
                      [pui-dropdown-max-width]="240"
                      [pui-dropdown-max-height]="200"
                      [items]="guides"
                      [text]="'+' + (guides?.length - MAX_GUIDE_AVATARS)"
                      size="s"></pui-avatar>
                    <div class="cycle" *ngIf="service.sessionType === SESSION_TYPE.ROUND_ROBIN">
                      <pui-icon svgIcon="pui:cycle" size="xs"></pui-icon>
                    </div>
                  </ng-container>
                </pui-avatar-group>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <!-- Actions -->
        <div class="pui-space_left-5" *ngIf="appearance.actions">
          <button
            class="dropdown__host-button"
            pui-dropdown
            [pui-dropdown-content]="dropdownContent"
            pui-dropdown-align="left"
            pui-icon-button
            size="m"
            type="button"
            appearance="flat"
            shape="rounded">
            <pui-icon svgIcon="pui:more-vertical"></pui-icon>
          </button>

          <ng-template #dropdownContent let-close="close">
            <div pui-list pui-a11y-keyboard-widget class="dropdown-content">
              <div
                *ngIf="appearance.actions?.edit"
                (puiClick)="close(); selectService.emit(service); selectServiceByIndex.emit(index)"
                pui-list-item="button"
                pui-a11y-keyboard-widget-item
                class="dropdown__option">
                <pui-icon class="pui-space_right-2" svgIcon="pui:edit" size="s"></pui-icon>
                <span i18n="@@Edit">Edit</span>
              </div>
              <div
                *ngIf="appearance.actions?.delete"
                (puiClick)="close(); deleteService.emit(service); deleteServiceByIndex.emit(index)"
                pui-list-item="button"
                pui-a11y-keyboard-widget-item
                class="dropdown__option">
                <pui-icon class="pui-space_right-2" svgIcon="pui:trash" size="s"></pui-icon>
                <span i18n="@@Delete">Delete</span>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #assignedGuidesDropdown let-guides="items">
  <div class="assigned-guides">
    <cdk-virtual-scroll-viewport autoHeight itemSize="20" role="list" class="assigned-guides__list">
      <pui-card
        class="assigned-guides__item"
        role="listitem"
        *cdkVirtualFor="let guide of guides; let first = first"
        [class.pui-space_top-2]="!first">
        <pui-card-header *puiLet="guide?.fullname || (guide | userName) as text">
          <pui-avatar pui-card-header-icon [avatarUrl]="guide.photo" [text]="text" size="s"></pui-avatar>
          <span pui-card-header-title pui-typography="body-s">
            {{ text }}
          </span>
        </pui-card-header>
      </pui-card>
    </cdk-virtual-scroll-viewport>
  </div>
</ng-template>

<ng-template #locationTemplate let-service>
  <div
    [ngSwitch]="service?.location?.connectionType || service?.connectionType"
    pui-typography="body-s"
    class="group-services__barge">
    <ng-container *ngSwitchCase="GUIDE_SERVICE_LOCATION_TYPE.IN_PERSON">
      <pui-icon svgIcon="pui:place" size="s" class="group-services__barge-icon"></pui-icon>
    </ng-container>
    <ng-container *ngSwitchCase="GUIDE_SERVICE_LOCATION_TYPE.PHONE">
      <pui-icon svgIcon="pui:phone" size="s" class="group-services__barge-icon"></pui-icon>
    </ng-container>
    <ng-container *ngSwitchCase="GUIDE_SERVICE_LOCATION_TYPE.ZOOM">
      <pui-icon svgIcon="pui:video" size="s" class="group-services__barge-icon"></pui-icon>
    </ng-container>
    <ng-container *ngSwitchCase="GUIDE_SERVICE_LOCATION_TYPE.IN_PLATFORM">
      <pui-icon svgIcon="pui:video" size="s" class="group-services__barge-icon"></pui-icon>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <pui-icon svgIcon="pui:video" size="s" class="group-services__barge-icon"></pui-icon>
    </ng-container>

    <span *ngIf="!service?.address">{{ service?.connectionType | connectionType }}</span>

    <ng-container *ngIf="service?.address">
      <span>{{ service.address }}</span>
    </ng-container>
  </div>
</ng-template>

<ng-template #priceTemplate let-service>
  <ng-container *ngIf="service.subscriptionPrice || service.price !== undefined">
    <div class="group-services__barge-wrapper">
      <pui-icon svgIcon="pui:tag" size="s" class="pui-space_right-1 group-services__barge-icon"></pui-icon>
      <!--1. If service has "subscriptionPrice"-->
      <ng-container
        *ngIf="service.subscriptionPrice; then subscriptionPriceTemplate; else oneTimePaymentTemplate"></ng-container>
    </div>
  </ng-container>

  <!-- 1.1 Then display the price of Subscription or Installment payment-->
  <ng-template #subscriptionPriceTemplate>
    <ng-container
      *ngIf="
        service?.totalPayments === null;
        then subscriptionPaymentTemplate;
        else installmentsPaymentTemplate
      "></ng-container>

    <ng-template #subscriptionPaymentTemplate>
      <span pui-typography="body-s"> ${{ service?.subscriptionPrice }} per {{ service?.subscriptionRecurrency }} </span>
    </ng-template>
    <ng-template #installmentsPaymentTemplate>
      <span pui-typography="body-s">
        ${{ service?.price }} or ${{ service?.subscriptionPrice }} per {{ service?.subscriptionRecurrency }} ({{
          service?.totalPayments
        }}
        total payments)
      </span>
    </ng-template>
  </ng-template>

  <!-- 1.2 Else display price of One time payment or Free-->
  <ng-template #oneTimePaymentTemplate>
    <ng-container *puiLet="service?.price || service?.subscriptionPrice || 0 as price">
      <span pui-typography="body-s" [ngPlural]="price">
        <ng-template ngPluralCase="=0">Free</ng-template>
        <ng-template ngPluralCase="other">${{ price }}</ng-template>
      </span>
    </ng-container>
  </ng-template>
</ng-template>

<!--START DATE BADGE START-->
<ng-template #startsDateTemplate let-service>
  <div class="group-services__barge-wrapper">
    <pui-icon svgIcon="pui:calendar" size="s" class="pui-space_right-1 group-services__barge-icon"></pui-icon>
    <div pui-typography="body-s">
      <span>Starts&nbsp;</span>
      <span>{{ service?.startDate | date: 'MMMM d, y' }}</span>
    </div>
  </div>
</ng-template>

<!-- SESSIONS START -->
<ng-template #sessionsTemplate let-service>
  <div class="service-sessions-barge">
    <pui-icon svgIcon="pui:grid" size="s" class="service-sessions-barge__icon"></pui-icon>
    <ul class="service-sessions-barge__list">
      <li *ngFor="let session of service.sessions" class="service-sessions-barge__list-item">
        <ng-container *ngIf="session.count > 1">{{ session.count }}&nbsp;x&nbsp;</ng-container>
        {{ session.template.name }}
      </li>
    </ul>
  </div>
</ng-template>
<!--SESSIONS END-->
