import { inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { GetOrderSummariesGQL } from './__generated__/get-payment-summaries.generated';
import { orderId } from '@app/features/enrollment/services/order-id';

export const getPaymentSummaries = () => {
  const getOrderSummariesGQL = inject(GetOrderSummariesGQL);
  const id = orderId();

  return getOrderSummariesGQL
    .fetch({
      orderId: id()!
    })
    .pipe(map(result => result.data.getOrder.summaries));
};
