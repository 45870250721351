import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import {
  createClientTagError,
  createClientTagSuccess,
  deleteClientTag,
  fetchClientTags,
  fetchClientTagsSuccess,
  resetClientsTags,
  updateClientTag
} from './client-tags-store.actions';
import { ClientTagsStoreState } from './commons/types';
// eslint-disable-next-line no-restricted-imports
import { keyBy } from 'lodash';

const initialState: ClientTagsStoreState = {
  status: 'initial',
  data: {}
};

export const clientTagsStoreReducer = createReducer(
  initialState,
  on(resetClientsTags, () => initialState),
  on(fetchClientTags, state =>
    produce(state, draft => {
      draft.status = 'loading';
    })
  ),
  on(fetchClientTagsSuccess, (state, { tags }) =>
    produce(state, draft => {
      draft.status = 'idle';
      draft.data = keyBy(tags, 'id') as never;
    })
  ),
  // on(createClientTag, (state, { tag }) =>
  //   produce(state, draft => {
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     draft.data[tag.name] = { ...tag, id: tag.name };
  //   })
  // ),
  on(createClientTagSuccess, (state, { tag }) =>
    produce(state, draft => {
      // delete draft.data[tag.name];
      draft.data[tag.id] = tag;
    })
  ),
  on(createClientTagError, deleteClientTag, (state, { id }) =>
    produce(state, draft => {
      delete draft.data[id];
    })
  ),
  on(updateClientTag, (state, { id, update }) =>
    produce(state, draft => {
      const tag = draft.data[id];

      if (!tag) {
        return;
      }

      draft.data[id] = { ...tag, ...update };
    })
  )
);
