import { MaskitoOptions } from '@maskito/core';

export const GREATER_THAN_1: MaskitoOptions = {
  mask: /^\d+$/,
  preprocessors: [
    ({ elementState }) => {
      let newValue = elementState.value.replace(/\D/g, '');
      newValue = newValue.replace(/^0+(?=\d)/, '');

      return {
        elementState,
        value: newValue || '1'
      };
    }
  ],
  postprocessors: [
    elementState => {
      let newValue = elementState.value.replace(/^0+(?=\d)/, '');
      newValue = Number(newValue) < 1 ? '1' : newValue;

      return {
        value: newValue,
        selection: elementState.selection
      };
    }
  ]
};
