import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ReCaptchaV3Service } from './re-captcha-v3.service';
import { NotificationsService } from '@awarenow/profi-ui-core';

@Injectable()
export class CaptchaInterceptor {
  constructor(private recaptchaV3Service: ReCaptchaV3Service, private notificationsService: NotificationsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (/\/auth\/(signup)$/.test(request.url)) {
      return this.recaptchaV3Service.execute('auth').pipe(
        switchMap(response => {
          const headers = request.headers
            // Token required to identify user
            .set('X-Recaptcha-Response', response)
            // Version of reCAPTCHA
            .set('X-Recaptcha-Version', 'v3');

          return next
            .handle(
              request.clone({
                headers
              })
            )
            .pipe(
              catchError((responseError: HttpErrorResponse) => {
                switch (responseError.error.name) {
                  case 'CaptchaLowScoreError':
                  case 'CaptchaFailedRequest':
                  case 'InvalidTokenType':
                  case 'InvalidVersionType':
                    this.notificationsService.error('Security', responseError.error.message);
                }

                return throwError(responseError);
              })
            );
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
