import { inject, Injectable, Injector } from '@angular/core';
import { PuiDrawerRef, PuiDrawerService } from '@awarenow/profi-ui-core';
import { ClientCardComponent } from '@app/features/client-card/client-card.component';
import { Router } from '@angular/router';
import { CLIENT_CARD_OUTLET } from '@app/features/client-card/client-card.routes';

interface ClientCardOpenProps {
  id: string;
  clientId: string;
}

@Injectable({
  providedIn: 'root'
})
export class ClientCardDrawerService {
  private drawer = inject(PuiDrawerService);
  private injector = inject(Injector);
  private router = inject(Router);
  private drawerRef?: PuiDrawerRef;

  open({ id, clientId }: Partial<ClientCardOpenProps>) {
    if (this.drawerRef) {
      return;
    }

    if (id === undefined && clientId === undefined) {
      throw new Error('Either id or clientId must be provided');
    }

    this.drawerRef = this.drawer.open(
      ClientCardComponent,
      {
        position: 'right',
        maxWidth: '600px',
        hideCloseButton: true,
        injector: this.injector
      },
      {
        id,
        clientId
      }
    );

    /**
     * Close the drawer and navigate to the parent route when the drawer is closed
     */
    const afterClosedSubscription = this.drawerRef.afterClosed$.subscribe(() => {
      this.drawerRef = undefined;
      this.router.navigate([{ outlets: { [CLIENT_CARD_OUTLET]: null } }]);
      afterClosedSubscription.unsubscribe();
    });
  }
}
