import { FieldPolicy, TypePolicy } from '@apollo/client';

const findAllOffersFieldPolicy: FieldPolicy = {
  keyArgs: ['input', ['filter', 'isActive', 'engagementId'], ['sort', 'createdAt']]
};

export const findAllOffersPolicy: TypePolicy = {
  fields: {
    findAllOffers: findAllOffersFieldPolicy
  }
};
