<div class="enrollment__wrapper">
  <div class="enrollment__stepper">
    <enrollment-steps class="w-full" />
  </div>

  <div class="enrollment__main">
    <div class="enrollment__content">
      <router-outlet />
    </div>
  </div>
</div>
