import { createReducer, on } from '@ngrx/store';
import * as actions from './client-assigned-guides-store.actions';
import { ClientsAssignedGuidesStore } from './commons/types';
import produce from 'immer';

const initialState: ClientsAssignedGuidesStore = {};

const reducer = createReducer(
  initialState,
  on(actions.resetAll, () => initialState),
  on(actions.fetchAssignedGuidesByClientIdSuccess, (state, { id, guides }) =>
    produce(state, draft => {
      draft[id] = guides;
    })
  )
);

export default reducer;
