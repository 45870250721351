import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, skip, switchMap } from 'rxjs/operators';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import * as actions from './relations-store.actions';
import { RelationsApiService } from './services/relations-api.service';

@Injectable()
export class RelationsStoreEffects {
  readonly fetchAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.fetchRelations),
      switchMap(() =>
        this.relationsApiService
          .getAll()
          .pipe(map(({ relations }) => ({ type: actions.fetchRelationsSuccess.type, relations })))
      )
    )
  );

  readonly workspaceChange$ = createEffect(() =>
    this.workspacesService.activeWorkspace$.pipe(
      skip(1),
      map(() => ({
        type: actions.resetAll.type
      }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly relationsApiService: RelationsApiService,
    private readonly workspacesService: WorkspacesService
  ) {}
}
