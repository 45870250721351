import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from '@angular/core';
import {
  NotificationsService,
  PuiAvatarModule,
  PuiButtonModule,
  PuiCardModule,
  PuiIconModule,
  PuiLinkModule,
  PuiPaperModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { currentUser } from '@app/core/auth/services';
import { UserNameModule } from '@app/shared/pipes/user-name/user-name.module';
import { changeAccount } from '../../../../services/change-account';
import { createOrder } from '../../../../services/create-order';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EnrollmentNavigationService } from '../../../../services/enrollment-navigation.service';
import { enrollmentOfferItemId } from '@app/features/enrollment/services/enrollment-offer-item-id';
import { getOrderedHostId } from '@app/features/offers/providers/enrollment-host-id.provider';

@Component({
  selector: 'booking-authorized-user',
  standalone: true,
  templateUrl: 'booking-authorized-user.component.html',
  imports: [
    PuiButtonModule,
    PuiTypographyModule,
    PuiAvatarModule,
    PuiLinkModule,
    UserNameModule,
    PuiCardModule,
    PuiPaperModule,
    PuiIconModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingAuthorizedUserComponent {
  private _createOrder = createOrder();
  private notificationService = inject(NotificationsService);
  private enrollmentNavigationService = inject(EnrollmentNavigationService);
  private destroyRef = inject(DestroyRef);

  protected changeAccount = changeAccount();
  protected isCreatingOrder = signal(false);

  readonly user = currentUser();
  readonly offerItemId = enrollmentOfferItemId();
  readonly orderedHostId = getOrderedHostId();

  async createOrder() {
    this.isCreatingOrder.set(true);
    this._createOrder(this.offerItemId, this.orderedHostId)
      .pipe(
        catchError(() => {
          this.notificationService.error('Something went wrong');
          this.isCreatingOrder.set(false);

          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ id: orderId, isCompleted }) => {
        if (isCompleted) {
          this.enrollmentNavigationService.toConfirmation(orderId);
        } else {
          this.enrollmentNavigationService.toPayment(orderId);
        }
      });
  }
}
