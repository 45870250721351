import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { Guide, CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import { UntypedFormControl } from '@angular/forms';
// eslint-disable-next-line no-restricted-imports
import { map as _map } from 'lodash';
import { Store } from '@ngrx/store';
import { selectSortedWorkspaceGuidesData } from '@app/screens/guide/guide-clients/guide-client/store/workspace-guides-store/workspace-guides-store.selectors';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import type { IWorkspaceMember } from '@app/modules/workspaces/types';

import { workspaceGuidesFetch } from '@app/screens/guide/guide-clients/guide-client/store/workspace-guides-store/workspace-guides-store.actions';
import { selectClientsAssignedGuidesState } from '@app/features/client-card/routes/client-details/components/assigned-team-memeber-selector/store/client-assigned-guides-store/client-assigned-guides-store.selectors';
import {
  assignGuidesForClient,
  fetchAssignedGuidesByClientId
} from '@app/features/client-card/routes/client-details/components/assigned-team-memeber-selector/store/client-assigned-guides-store/client-assigned-guides-store.actions';

@Component({
  selector: 'app-assigned-team-memeber-selector',
  templateUrl: './assigned-team-memeber-selector.component.html',
  styleUrls: ['./assigned-team-memeber-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PuiDestroyService]
})
export class AssignedTeamMemeberSelectorComponent implements OnInit {
  readonly MAX_DISPLAYED_GUIDES = 2;

  control!: UntypedFormControl;

  readonly guides$ = this.store$.select(selectSortedWorkspaceGuidesData);
  readonly assignedGuides$ = this.store$.select(selectClientsAssignedGuidesState).pipe(
    filter(state => this.clientId !== undefined && state[this.clientId] !== undefined),
    map(state => state[this.clientId])
  );

  private valueChangeSubscription?: Subscription;
  private clientId!: CRMClient['relationId'];

  @Input('clientId')
  set clientIdSetter(clientId: CRMClient['relationId']) {
    if (!clientId || this.clientId === clientId) {
      return;
    }

    this.clientId = clientId;
    // Fetch client assigned guides
    this.store$.dispatch(fetchAssignedGuidesByClientId({ id: clientId }));
  }

  constructor(
    private readonly store$: Store,
    @Inject(PuiDestroyService) private readonly destroy$: Observable<void>,
    private cdRef: ChangeDetectorRef
  ) {
    this.assignedGuides$.pipe(takeUntil(this.destroy$)).subscribe(assignedGuides => {
      this.handleValueChange(assignedGuides);
      this.cdRef.markForCheck();
    });
  }
  ngOnInit(): void {
    this.store$.dispatch(workspaceGuidesFetch());
  }

  isGuideSelected(guide: Guide): boolean {
    return this.control?.value?.includes(guide.userId);
  }

  private handleValueChange(assignedGuides: IWorkspaceMember[] = []): void {
    this.valueChangeSubscription?.unsubscribe();
    this.valueChangeSubscription = undefined;

    this.control = new UntypedFormControl(_map(assignedGuides || [], 'userId'));
    this.valueChangeSubscription = this.control.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.destroy$))
      // eslint-disable-next-line rxjs/no-nested-subscribe
      .subscribe((guides: IWorkspaceMember['userId'][]) => {
        this.store$.dispatch(assignGuidesForClient({ id: this.clientId, guides }));
      });
  }
}
