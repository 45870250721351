import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { config } from '@app/core';

type RelationsGetAllResult = { relations: unknown[] };

@Injectable()
export class RelationsApiService {
  constructor(private readonly httpClient: HttpClient) {}

  getAll(): Observable<RelationsGetAllResult> {
    return this.httpClient.get<RelationsGetAllResult>(`${config.apiPath}/user/guide/relations`);
  }
}
