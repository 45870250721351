<pui-avatar-group
  role="button"
  tabindex="0"
  [attr.aria-expanded]="open"
  [size]="size"
  [(pui-dropdown)]="open"
  [pui-dropdown-content]="dropdownContent"
  (click)="$event.stopPropagation(); open = !open">
  <pui-avatar
    *ngFor="let item of items.slice(0, max); trackBy: trackByAvatarUrl"
    [avatarUrl]="item.avatarUrl"
    [text]="item.text"></pui-avatar>
  <ng-container *ngIf="items.length - max > 0" ngProjectAs="pui-avatar">
    <pui-avatar aria-hidden="true" [text]="'+' + (items.length - max)" [size]="size"></pui-avatar>
  </ng-container>
</pui-avatar-group>

<ng-template #dropdownContent>
  <!--  ToDo this should be fixed when we replace tui-dropdown-box with pui-dropdown-box-->
  <ul pui-list [style.backgroundColor]="'#fff'">
    <li pui-list-item *ngFor="let item of items; let i = index; trackBy: trackByAvatarUrl">
      <pui-list-item-icon>
        <pui-avatar [avatarUrl]="item.avatarUrl" [text]="item.text" [size]="size"></pui-avatar>
      </pui-list-item-icon>
      {{ item.text }}
    </li>
  </ul>
</ng-template>
