import { NotificationsService } from '@awarenow/profi-ui-core';
import { of } from 'rxjs';
import { catchError, filter, map, skip, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { getClientsByClientId } from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { ClientHistoryApiServices } from '../../services/client-history-api.services';
import {
  fetchClientHistory,
  fetchClientHistoryError,
  fetchClientHistorySuccess,
  resetClientsHistoryState
} from './clients-history.actions';

@Injectable()
export class ClientsHistoryEffects {
  /**
   * Fetch history of client by { clientId }
   */
  readonly fetchClientHistory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchClientHistory.type),
      switchMap(({ clientId }) =>
        this._store$.select(getClientsByClientId(clientId)).pipe(
          filter(client => !!client),
          map(client => {
            if (client) {
              return client;
            } else {
              throw Error(`Client by "${clientId}" id not found!`);
            }
          }),
          switchMap(client =>
            this._clientHistoryApiServices
              .getClientHistory(
                client.type === 'guideContact'
                  ? {
                      relationId: client.relationId
                    }
                  : {
                      clientId: client.clientId
                    }
              )
              .pipe(
                map(data => ({
                  type: fetchClientHistorySuccess.type,
                  clientId,
                  data
                }))
              )
          ),
          catchError(() => {
            return of({
              type: fetchClientHistoryError.type,
              clientId
            });
          })
        )
      )
    )
  );

  /**
   * Handle error of client history request
   */
  readonly fetchClientHistoryError$ = createEffect(() =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._actions$.pipe(
      ofType(fetchClientHistoryError.type),
      switchMap(() =>
        of(
          this._notificationsService.error(`Internal error', 'Error while loading client history!`, {
            timeOut: 2000
          })
        )
      )
    )
  );

  /**
   * Reset all store when workspace is change.
   */
  readonly workspaceChange$ = createEffect(() =>
    this._workspacesService.activeWorkspace$.pipe(
      skip(1),
      map(() => ({
        type: resetClientsHistoryState.type
      }))
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store$: Store,
    private readonly _clientHistoryApiServices: ClientHistoryApiServices,
    private readonly _notificationsService: NotificationsService,
    private readonly _workspacesService: WorkspacesService
  ) {}
}
