import produce from 'immer';
import { createReducer, on } from '@ngrx/store';
import {
  fetchClientHistory,
  fetchClientHistoryError,
  fetchClientHistorySuccess,
  resetClientHistoryState,
  resetClientsHistoryState
} from './clients-history.actions';
import { ClientsHistory } from './types';

const initialState: ClientsHistory = {};

export const clientsHistoryReducer = createReducer(
  initialState,
  on(resetClientsHistoryState, () => initialState),
  on(resetClientHistoryState, (state, { clientId }) =>
    produce(state, draft => {
      // @ts-expect-error TS2322
      draft[clientId] = undefined;
    })
  ),
  on(fetchClientHistory, (state, { clientId }) =>
    produce(state, draft => {
      if (!draft[clientId]) {
        draft[clientId] = {
          status: 'loading',
          // @ts-expect-error TS2322
          data: null
        };
      }
    })
  ),
  on(fetchClientHistorySuccess, (state, { clientId, data }) =>
    produce(state, draft => {
      draft[clientId] = {
        status: 'idle',
        data
      };
    })
  ),
  on(fetchClientHistoryError, (state, { clientId }) =>
    produce(state, draft => {
      if (draft[clientId].data) {
        draft[clientId].status = 'error';
      } else {
        draft[clientId] = {
          status: 'error',
          // @ts-expect-error TS2322
          data: null
        };
      }
    })
  )
);
