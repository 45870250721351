import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { AlternativeAuthProvidersService } from '@app/modules/auth/services/alternative-auth-providers.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthProviders } from '@app/shared/enums/auth-providers';
import { RuntimeConfigService } from '@app/core/runtime-config/runtime-config.service';
import { AsyncPipe } from '@angular/common';
import { BrandingService } from '@app/core/branding/branding.service';
import { PuiButtonModule, PuiIconModule } from '@awarenow/profi-ui-core';
import { onAuthorized } from '@app/features/auth/providers/auth-options';

@Component({
  selector: 'third-party-auth',
  standalone: true,
  templateUrl: 'third-party-auth.component.html',
  providers: [AlternativeAuthProvidersService],
  imports: [AsyncPipe, PuiButtonModule, PuiIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThirdPartyAuthComponent implements OnInit {
  protected thirdPartyAuthService = inject(AlternativeAuthProvidersService);
  protected brandingService = inject(BrandingService);
  private runtimeConfigService = inject(RuntimeConfigService);
  private onThirdPartyAuth$ = this.thirdPartyAuthService.onAuth().pipe(takeUntilDestroyed());
  private onAuthorized = onAuthorized();

  ngOnInit() {
    this.handleThirdPartyAuth();
    this.insertSSOAuthFrame();
  }

  private handleThirdPartyAuth() {
    this.onThirdPartyAuth$.subscribe(loggedIn => {
      if (loggedIn) {
        if (this.thirdPartyAuthService.signInCanNot) {
          // TODO Show error notification.
        } else {
          this.onAuthorized();
        }
      }

      if (
        !loggedIn &&
        this.thirdPartyAuthService.signUpUser &&
        this.thirdPartyAuthService.signUpProvider === AuthProviders.SSO
      ) {
        this.thirdPartyAuthService.signUp({
          isGuide: false,
          isClient: true,
          subscribeNews: false
        });
      }
    });
  }

  private insertSSOAuthFrame() {
    const isClientSSOEnabled = this.runtimeConfigService.get('isClientSSOEnabled') || false;

    if (isClientSSOEnabled) {
      this.thirdPartyAuthService.insertSSOAuthFrame(true, true);
    }
  }
}
