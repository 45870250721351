import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, skip, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { Store } from '@ngrx/store';
import { selectWorkspaceGuidesData } from '@app/screens/guide/guide-clients/guide-client/store/workspace-guides-store/workspace-guides-store.selectors';
import * as actions from './client-assigned-guides-store.actions';
// eslint-disable-next-line no-restricted-imports
import { intersectionWith } from 'lodash';
import { ClientAssignedMemberApiService } from '@app/features/client-card/routes/client-details/components/assigned-team-memeber-selector/services/client-assigned-member-api.service';

@Injectable()
export class ClientAssignedGuidesStoreEffects {
  readonly fetchAssignedGuidesByClientId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.fetchAssignedGuidesByClientId),
      switchMap(({ id }) =>
        this.clientAssignedMemberApiService.getAssignedGuidesByClientId(id).pipe(
          map(({ assignedMembers: guides }) => ({
            type: actions.fetchAssignedGuidesByClientIdSuccess.type,
            id,
            guides
          })),
          catchError(() => of({ type: actions.fetchAssignedGuidesByClientIdError.type }))
        )
      )
    )
  );

  readonly assignGuidesForClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.assignGuidesForClient),
      switchMap(({ id, guides }) =>
        this.clientAssignedMemberApiService.assignGuides(id, guides).pipe(
          withLatestFrom(this.store$.select(selectWorkspaceGuidesData)),
          map(([, workspaceGuides]) => ({
            type: actions.assignGuidesForClientSuccess.type,
            id,
            guides: intersectionWith(workspaceGuides, guides, (guide, id) => guide.userId === id)
          })),
          catchError(() =>
            of({
              type: actions.assignGuidesForClientError.type
            })
          )
        )
      )
    )
  );

  readonly workspaceChange$ = createEffect(() =>
    this.workspacesService.activeWorkspace$.pipe(
      skip(1),
      map(() => ({
        type: actions.resetAll.type
      }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly clientAssignedMemberApiService: ClientAssignedMemberApiService,
    private readonly workspacesService: WorkspacesService
  ) {}
}
