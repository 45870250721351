import { NgModule } from '@angular/core';
import { GuideClientTagSelectorComponent } from './guide-client-tag-selector.component';
import { CommonModule } from '@angular/common';
import {
  PuiButtonModule,
  PuiColorSelectorModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiLetModule,
  PuiLinkModule,
  PuiList2Module,
  PuiOptionModule,
  PuiTableModule,
  PuiTagModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { ClientTagsStoreModule } from '@app/screens/guide/guide-clients/guide-client/store/client-tags/client-tags-store.module';

@NgModule({
  declarations: [GuideClientTagSelectorComponent],
  imports: [
    CommonModule,
    PuiLetModule,
    PuiIconModule,
    PuiLinkModule,
    PuiDropdownModule,
    PuiTagModule,
    PuiTableModule, // deprecated
    PuiButtonModule,
    PuiOptionModule,
    PuiList2Module,
    PuiDropdownModule,
    PuiTypographyModule,
    PuiColorSelectorModule,
    ClientTagsStoreModule
  ],
  exports: [GuideClientTagSelectorComponent]
})
export class GuideClientTagSelectorModule {}
