import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  PuiButtonModule,
  PuiInputCheckboxModule,
  PuiInputModule,
  PuiLinkModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { signUp } from '@app/core/auth/services';
import { lastValueFrom } from 'rxjs';
import { onAuthorized } from '@app/features/auth/providers/auth-options';

@Component({
  selector: 'sign-up',
  standalone: true,
  templateUrl: 'sign-up-component.html',
  imports: [
    PuiButtonModule,
    PuiInputModule,
    ReactiveFormsModule,
    PuiInputCheckboxModule,
    PuiTypographyModule,
    PuiLinkModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpComponent {
  email = input.required<string>();

  protected form = computed(
    () =>
      new FormGroup({
        email: new FormControl<string>(this.email(), {
          nonNullable: true
        }),
        password: new FormControl<string>('', [Validators.required]),
        firstName: new FormControl<string>('', [Validators.pattern(/^\p{L}+([ _-]\p{L}+)*$/u), Validators.required]),
        lastName: new FormControl<string>('', [Validators.pattern(/^\p{L}+([ _-]\p{L}+)*$/u), Validators.required]),
        agreeWithTerms: new FormControl<boolean>(false, [Validators.requiredTrue])
      })
  );

  private signUp = signUp();
  private onAuthorized = onAuthorized();

  async submit() {
    const form = this.form();

    const result = await lastValueFrom(
      this.signUp({
        ...form.value,
        isClient: true
      })
    );

    this.onAuthorized(result);
  }
}
