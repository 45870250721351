<div class="pui-space_bottom-4">
  <h1 pui-typography="h1" i18n="@@chooseTemplateD">Choose template</h1>
</div>

<pui-input-field class="pui-space_bottom-4" [puiPrefix]="actions">
  <ng-template #actions>
    <pui-icon svgIcon="pui:search" size="s"></pui-icon>
  </ng-template>
  <input
    [(ngModel)]="filter"
    class=""
    pui-typography="body-s"
    pui-input-text
    size="s"
    type="text"
    placeholder="Search session" />
</pui-input-field>

<!--LOADING-->
<ng-container *ngIf="isLoading$ | async">
  <div class="guide-services-empty guide-services-empty_loading">
    <div class="guide-services-empty__container">
      <pui-icon svgIcon="pui:refresh-cw" size="m" class="guide-services-empty__icon"></pui-icon>
      <br />
      <span i18n="@@fetchingData:title" pui-typography="h3" class="guide-services-empty__title">Fetching data</span>
      <br />
      <small i18n="@@fetchingData:description" pui-typography="body-s">
        This will only take a moment. Please wait...
      </small>
    </div>
  </div>
</ng-container>

<app-group-services
  [services]="templates$ | async"
  [searchText]="filter"
  [appearance]="{
    header: false,
    hostsAvatars: true
  }"
  (selectService)="selectService($event)"></app-group-services>
