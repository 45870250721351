import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PuiNavigationListMode } from '@awarenow/profi-ui-core';
import { ScreenSize, StyleBreakpointsProviderService } from '@app/modules/ui-kit/_base/_common/screen/services';

const INITIAL_MODE: PuiNavigationListMode = 'full';

@Injectable()
export class SidenavModeService extends BehaviorSubject<PuiNavigationListMode> {
  // @ts-expect-error TS2564
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _autoCloseEnabled: boolean;

  constructor(private _screen: StyleBreakpointsProviderService) {
    super(INITIAL_MODE);
  }

  toggle(): void {
    const newMode: PuiNavigationListMode = this.getValue() === 'short' ? 'full' : 'short';

    this.next(newMode);
  }

  set(mode: PuiNavigationListMode): void {
    if (!this._autoCloseEnabled) {
      this.next(mode);
    }
  }
}
