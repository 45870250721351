import { inject, InjectionToken, signal, WritableSignal } from '@angular/core';
import { OrderStep } from '@app/graphql/types';

const CURRENT_STEP_KEY = new InjectionToken<WritableSignal<OrderStep>>('CURRENT_STEP_KEY');

export const provideCurrentStep = (step: OrderStep) => {
  return {
    provide: CURRENT_STEP_KEY,
    useValue: signal<OrderStep>(step)
  };
};

export const currentStep = () => inject(CURRENT_STEP_KEY);
