import { ChangeDetectionStrategy, Component, DestroyRef, inject, Injector } from '@angular/core';
import { PUI_IS_MOBILE, PUI_IS_TABLET, PuiDrawerService } from '@awarenow/profi-ui-core';
import { combineLatest } from 'rxjs';
import { map, first, filter } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PuiButtonModule, PuiIconModule, PuiLayoutModule, PuiScrollableModule } from '@awarenow/profi-ui-core';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { AlertsModule } from '@app/modules/alerts/alerts.module';
import { ScreenModule } from '@app/modules/ui-kit/_base/_common/screen/screen.module';

import { SidenavModule } from '@app/modules/sidenav/sidenav.module';
import { PrimaryLayoutMobileHeaderComponent } from './components/primary-layout-mobile-header/primary-layout-mobile-header.component';
import { NavigationModeDirective } from './behaviors/navigation-mode.behavior';
import { SidenavComponent } from '@app/modules/sidenav';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Component({
  selector: 'app-primary-layout',
  templateUrl: './primary-layout.component.html',
  styleUrls: ['primary-layout.component.scss'],
  standalone: true,
  imports: [AsyncPipe, AlertsModule, RouterModule, SidenavModule, PuiLayoutModule, PrimaryLayoutMobileHeaderComponent],
  hostDirectives: [NavigationModeDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimaryLayoutComponent {
  showHeader$ = combineLatest([inject(PUI_IS_TABLET), inject(PUI_IS_MOBILE)]).pipe(
    // @ts-expect-error
    map(([isTablet, isMobile]) => isTablet || isMobile)
  );

  private drawer = inject(PuiDrawerService);
  private injector = inject(Injector);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);

  openSidenav() {
    const ref = this.drawer.open(SidenavComponent, {
      position: 'left',
      maxWidth: '264px',
      hideCloseButton: true,
      injector: this.injector
    });

    // Handle routing
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        first(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(event => {
        ref.close();
      });
  }
}
