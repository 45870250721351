import { OfferItemServiceType, OfferItemType } from '@app/graphql/types';

export const OFFERS_ROUTE = 'offers';
export const OFFER_ID_ROUTE = 'offerId';
export const OFFER_HOST_ROUTE = 'hosts';
export const OFFER_HOST_ID_ROUTE = 'offerHostId';
export const OFFER_ITEM_ROUTE = 'offer-items';
export const OFFER_ITEM_ID_ROUTE = 'offerItemId';
export const OFFER_SERVICE_ROUTE = 'offer-services';
export const OFFER_SERVICE_ID_ROUTE = 'offerServiceId';
export const OFFER_ITEM_BUNDLE_ROUTE = OfferItemType.Bundle;
export const OFFER_ITEM_SESSION_ROUTE = OfferItemType.Session;
export const OFFER_ITEM_ENGAGEMENT_ROUTE = OfferItemType.Engagement;
export const OFFER_SERVICE_ENGAGEMENT_ROUTE = OfferItemServiceType.Engagement;

export const ORDERED_HOST_ID = 'orderedHostId';
