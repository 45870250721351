import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ClientTagsStoreEffects } from './client-tags-store.effects';
import { CLIENT_TAGS_STORE } from './commons/consts';
import { clientTagsStoreReducer } from './client-tags-store.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(CLIENT_TAGS_STORE, clientTagsStoreReducer),
    EffectsModule.forFeature([ClientTagsStoreEffects])
  ]
})
export class ClientTagsStoreModule {}
