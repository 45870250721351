import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AUTH_OUTLET } from '@app/features/auth/auth.routes';

export const goToSignUp = () => {
  const router = inject(Router);
  const route = inject(ActivatedRoute);

  return (email: string) =>
    router.navigate([{ outlets: { [AUTH_OUTLET]: [email, 'sign-up'] } }], {
      relativeTo: route,
      skipLocationChange: true
    });
};
