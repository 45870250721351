import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignedTeamMemeberSelectorComponent } from './assigned-team-memeber-selector.component';
import {
  PuiAvatarGroupModule,
  PuiAvatarModule,
  PuiCardModule,
  PuiInputCheckboxModule,
  PuiLetModule,
  PuiLinkModule,
  PuiOptionModule,
  PuiSelectModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@libs/shared/shared.module';
import { ClientAssignedGuidesStoreModule } from './store/client-assigned-guides-store/client-assigned-guides-store.module';

@NgModule({
  declarations: [AssignedTeamMemeberSelectorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PuiOptionModule,
    PuiSelectModule,
    SharedModule,
    PuiLetModule,
    PuiAvatarModule,
    PuiAvatarGroupModule,
    PuiCardModule,
    PuiInputCheckboxModule,
    PuiTypographyModule,
    PuiLinkModule,
    ClientAssignedGuidesStoreModule
  ],
  exports: [AssignedTeamMemeberSelectorComponent]
})
export class AssignedTeamMemeberSelectorModule {}
