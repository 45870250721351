import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CLIENT_CARD_DETAILS,
  CLIENT_CARD_ENGAGEMENTS,
  CLIENT_CARD_HISTORY,
  CLIENT_CARD_NOTES,
  CLIENT_CARD_OUTLET
} from '@app/features/client-card/client-card.routes';

const createClientCardNavigator = (path: string) => {
  const router = inject(Router);
  const route = inject(ActivatedRoute);

  return (args: { [key: string]: any }) =>
    router.navigate([{ outlets: { [CLIENT_CARD_OUTLET]: [path] } }], {
      queryParams: args,
      queryParamsHandling: 'merge',
      relativeTo: route,
      skipLocationChange: true
    });
};

export const openClientDetails = () => createClientCardNavigator(CLIENT_CARD_DETAILS);
export const openClientEngagements = () => createClientCardNavigator(CLIENT_CARD_ENGAGEMENTS);
export const openClientNotes = () => createClientCardNavigator(CLIENT_CARD_NOTES);
export const openClientHistory = () => createClientCardNavigator(CLIENT_CARD_HISTORY);
