// Angular core imports
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PaymentTypeComponent } from './components/payment-type/payment-type.component';
import { PaymentSummaryComponent } from './components/payment-summary/payment-summary.component';
import { getPaymentSummaries } from './services/get-payment-summaries';
import { orderId } from '../../services/order-id';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'payment',
  standalone: true,
  templateUrl: './payment.component.html',
  imports: [PaymentTypeComponent, PaymentSummaryComponent, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentComponent {
  private cdRef = inject(ChangeDetectorRef);

  protected summaries$ = getPaymentSummaries();
  protected selectedPaymentType?: number;

  readonly orderId = orderId();

  constructor() {
    this.summaries$.pipe(takeUntilDestroyed()).subscribe(summaries => {
      if (summaries?.length === 1) {
        this.setSelectedPaymentType(0);
      }
    });
  }

  protected setSelectedPaymentType(paymentType: number) {
    this.selectedPaymentType = paymentType;
    this.cdRef.markForCheck();
  }
}
