/**
 * @internal only form GuideClintHistory
 */
import { ClientTimelineEvent } from '@app/features/client-card/routes/client-history/types';
import { ClientTimelineEventUtils } from '@app/features/client-card/routes/client-history/client-timeline-event.utils';

export class ClientHistoryViewModel {
  readonly description = ClientTimelineEventUtils.getDescriptionWithGuide(
    this._event,
    this.config.hasHostedByDisplayed
  );
  readonly author = ClientTimelineEventUtils.getAuthor(this._event);
  readonly comment = this._event.contentText;
  readonly timestamp = this._event.timestamp;

  constructor(
    readonly _event: ClientTimelineEvent,
    private readonly config: {
      // Should show hosted by
      hasHostedByDisplayed: boolean;
    }
  ) {}
}
