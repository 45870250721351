import { Directive, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SidenavModeService } from '@app/modules/sidenav/services/mode.service';
import { map } from 'rxjs/operators';

@Directive({
  host: {
    '[class._is-collapsed]': 'isCollapsed()'
  },
  standalone: true
})
export class NavigationModeDirective {
  private sidenav = inject(SidenavModeService);

  isCollapsed = toSignal(this.sidenav.pipe(map(mode => mode === 'short')), { initialValue: false });
}
