import { Routes } from '@angular/router';
import { PaymentComponent } from './payment.component';
import { OrderStep } from '@app/graphql/types';

export const PAYMENT_ROUTE = 'payment';

export const routes: Routes = [
  {
    path: PAYMENT_ROUTE,
    component: PaymentComponent,
    data: { step: OrderStep.Payment }
  }
];

export default routes;
