<div class="primary-layout__container">
  <!--SIDE NAVIGATION-->
  <!--  TODO Back mobile menu behavior-->
  <aside class="primary-layout__aside">
    <app-sidenav #appSidenav="appSidenav" />
  </aside>
  <!--MAIN-->
  <main class="primary-layout__main">
    <!--  TODO Back mobile header behavior -->
    <!--MOBILE HEADER-->
    <!--    @if (!view.sidenav.isForceHideHeader) {-->
    <!--      <app-primary-layout-header *MobileAOrSmallTablet (toggle-sidenav)="toggle()" />-->
    <!--    }-->

    @if (showHeader$ | async) {
      @defer {
        <primary-layout-mobile-header (toggleSidenav)="openSidenav()" />
      }
    }

    <!-- USER ALERTS BLOCK -->
    <!-- TODO: Has issue when remove that block content height is auto -->
    <app-alerts-provider />

    <!--PAGE CONTENT-->
    <pui-content class="primary-layout__content">
      <router-outlet></router-outlet>
    </pui-content>
  </main>
</div>
