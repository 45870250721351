import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import config from '@app/core/config/config';
import { ClientTimelineResponse } from '../types';

@Injectable({
  providedIn: 'root'
})
export class ClientHistoryApiServices {
  constructor(private readonly _httpClient: HttpClient) {}

  getClientHistory(
    params:
      | {
          relationId: number;
        }
      | { clientId: number }
  ): Observable<ClientTimelineResponse> {
    return this._httpClient.get<ClientTimelineResponse>(`${config.apiPath}/user/guide/relations/timeline`, {
      params
    });
  }
}
