<div class="service-form__wrapper">
  <div class="service-form__header">
    <div class="pui-space_bottom-4">
      <ng-container *puiLet="form.get('service')?.value as service">
        <h2 *ngIf="!service?.name" pui-typography="h2" i18n="@@CoachingSession">Coaching session</h2>
        <h2 *ngIf="service?.name" pui-typography="h2">{{ service.name }}</h2>
      </ng-container>
    </div>

    <button
      *ngIf="!(routeService.options$ | async)?.disableBackButton"
      pui-link
      class="pui-space_bottom-4"
      type="button"
      data-qa-id="service__back-button"
      (click)="back()">
      <pui-icon svgIcon="pui:chevron-left" size="s" class="pui-space_right-1"></pui-icon>
      <span i18n="@@Back" class="pui-space_left-1">Back</span>
    </button>

    <div
      *ngIf="hideClientSelector && (serviceSchedulingFiltersService.chosenClients$ | async)?.length"
      class="pui-space_bottom-4">
      <app-selected-users-card
        [clients]="serviceSchedulingFiltersService.chosenClients$ | async"></app-selected-users-card>
    </div>
  </div>
  <!-- IDLE -->
  <form [ngClass]="{ hidden: !(isInitiated$ | async) }" class="pui-form service-form" [formGroup]="form">
    <ng-container *puiLet="form.get('service')?.value as service">
      <!--1st ROW-->
      <div *ngIf="service" class="pui-form__row pui-space_bottom-4">
        <app-full-date
          [hostControl]="form.get('host')"
          [chosenClients]="serviceSchedulingFiltersService.chosenClients$ | async"
          [service]="service"
          formControlName="schedule"></app-full-date>
      </div>

      <div class="pui-form__row" *ngIf="isUnavailableDateTime">
        <pui-alert type="info">
          <label formControlName="doubleBooking" pui-input-checkbox i18n="@@doubleBookingAcknowledge"
            >I confirm that this session should be booked at the time that is already booked or outside the
            availability.
          </label>
        </pui-alert>
      </div>

      <div *ngIf="isRecurring && !(inviteToAllRecurring$ | async) && (hasRepeat$ | async)" class="pui-space_bottom-4">
        <app-recurrence-form #recurrenceFormComponent formControlName="recurrence"></app-recurrence-form>
      </div>

      <!--2nd ROW-->
      <div class="pui-form__row pui-space_bottom-4">
        <!--CLIENTS CHOOSE FIELD-->
        <div *ngIf="!hideClientSelector" class="pui-form__field pui-space_bottom-2">
          <ng-container *puiLet="clients$ | async as clients">
            <div class="pui-form__field">
              <label pui-input-label [required]="true" i18n="@@Client">Client</label>
              <app-user-select
                class="service-form__user-select"
                [selectionList]="clients"
                [selectionsLimit]="service?.seatsPerTimeSlot || 1"
                [preselectedList]="serviceSchedulingFiltersService.chosenClients$ | async"
                (changeSelectedList)="change($event)"></app-user-select>
            </div>
          </ng-container>
        </div>
        <!--PRICE FIELD-->
        <!-- TODO: will chage next sprint -->
        <div class="pui-form__field service-form__price" *ngIf="showPrice">
          <label pui-input-label>Price</label>
          <pui-input-currency [puiSuffix]="actions">
            <input
              formControlName="price"
              pui-typography="body-s"
              pui-input-text
              required
              type="text"
              placeholder="Enter price" />

            <ng-template #actions>
              <pui-icon
                svgIcon="pui:info"
                size="s"
                placement="top-right"
                i18n-ngbTooltip="@@proposeServicePriceTooltip"
                ngbTooltip="You may change the price of the service"></pui-icon>
            </ng-template>
          </pui-input-currency>
        </div>
      </div>
      <!--3rd ROW-->
      <ng-container *ngIf="showNoteParticipantsMessage$ | async as isNoteMessageVisible">
        <div *ngIf="isNoteMessageVisible" class="client-msg-note">
          <pui-icon svgIcon="pui:pin"></pui-icon>
          <span i18n="@@ClientNoteMessageText"
            >Please note that participants who haven't completed the previous module yet, can't attend the
            session.</span
          >
          <pui-icon
            class="client-msg-note__close-icon"
            (click)="showNoteParticipantsMessage$.next(false)"
            svgIcon="pui:x"></pui-icon>
        </div>
      </ng-container>
      <!--4rd ROW-->
      <ng-container *ngIf="activeClientsWarningMessage$ | async as activeClientsWarningMessage">
        <div *ngIf="activeClientsWarningMessage" class="client-msg-note">
          <pui-icon svgIcon="pui:pin"></pui-icon>
          <span i18n="@@ActiveClientsWarningMessage">You have no active participants for this session.</span>
          <pui-icon
            class="client-msg-note__close-icon"
            (click)="activeClientsWarningMessage$.next(false)"
            svgIcon="pui:x"></pui-icon>
        </div>
      </ng-container>

      <ng-container *ngIf="inviteToAllRecurring$ | async">
        <div class="pui-form__row pui-space_bottom-2">
          <pui-alert class="pui-form__field" type="info">
            <pui-icon class="icon" svgIcon="pui:info" size="xs"></pui-icon>
            <span
              i18n="@@hisSessionIsRecurrentPleaseChoose:This session is recurrent. Please choose one of the options."
              >This session is recurrent. Please choose one of the options.</span
            >
          </pui-alert>
        </div>
        <div class="pui-form__row pui-space_bottom-2" pui-typography="h4" i18n="@@InviateTo:Invite to">Invite to</div>
        <div class="pui-form__row pui-space_bottom-2 d-flex flex-column">
          <label pui-input-radio formControlName="inviteToAllRecurring" [value]="false">
            <span i18n="@@ThisSession:This session">This session</span>
          </label>
          <label pui-input-radio formControlName="inviteToAllRecurring" [value]="true">
            <span i18n="@@AllSessionInReccurrence:All sessions in recurrence">All sessions in recurrence</span>
          </label>
        </div>
      </ng-container>

      <!--4th ROW-->
      <div class="pui-form__row pui-space_bottom-2">
        <div class="pui-form__field">
          <label
            class="pui-space_bottom-1"
            pui-input-label
            i18n="@@MessageToClient"
            data-qa-id="service__message-to-client"
            >Message to client</label
          >

          <pui-input-field>
            <textarea
              [style.resize]="'none'"
              formControlName="note"
              rows="4"
              pui-typography="body-s"
              pui-input-text
              type="text"
              i18n-placeholder="@@chatMessageBoxWriteSomething"
              placeholder="Write something..."></textarea>
          </pui-input-field>
        </div>
      </div>

      <div *ngIf="!form.get('service')?.value?.disableGuests">
        <label *ngIf="form.get('guests')?.controls.length" pui-input-label i18n="@@Guests">Guests</label>

        <div class="add-guests__item" *ngFor="let guest of form.get('guests')?.controls; let idx = index">
          <pui-input-field class="pui-space_right-6">
            <input [formControl]="guest" placeholder="guest@example.com" pui-input-text type="text" [required]="true" />
          </pui-input-field>
          <pui-input-error [formControl]="guest" role="alert"></pui-input-error>
          <pui-icon (click)="removeGuestAt(idx)" svgIcon="pui:trash"></pui-icon>
        </div>

        <button (click)="addGuest()" class="pui-space_bottom-6" pui-link>
          <pui-icon svgIcon="pui:user-plus" size="s"></pui-icon>
          <span i18n="@@AddGuests" class="pui-space_left-1">Add guests</span>
        </button>
      </div>
    </ng-container>
  </form>

  <!--LOADING-->
  <ng-container *ngIf="!(isInitiated$ | async)">
    <app-service-scheduling-stub class="h-100" [view]="StubViewMode.LOADING"></app-service-scheduling-stub>
  </ng-container>
</div>

<div class="service-form__submit">
  <button
    [disabled]="invalid || !(isInitiated$ | async)"
    [isLoading]="blocked$ | async"
    (click)="submit()"
    pui-button
    data-qa-id="service__send-invite-button">
    <span i18n="@@SendInvite">Send invite</span>
  </button>
</div>
