import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { currentClientId } from '../../../../services/current-client-id';
import { FetchClientServicesGQL, InactiveClientServicesGQL } from './__generated__/fetch-client-services.generated';
import {
  PuiCollapseModule,
  PuiProgressIconComponent,
  PuiSegmentControlTabDirective,
  PuiSegmentedControlComponent,
  PuiTagModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { PlatformStyleModule } from '@platformStyle/platform-style.module';
import { OfferItemServiceComponent } from '@app/features/offers/components/offer-item-service/offer-item-service.component';

enum ClientServiceViewType {
  PROGRESS,
  AVAILABLE_SESSIONS
}

@Component({
  selector: 'client-services',
  standalone: true,
  templateUrl: 'client-services.component.html',
  styleUrl: 'client-services.component.scss',
  imports: [
    AsyncPipe,
    PuiProgressIconComponent,
    RouterLink,
    PuiSegmentedControlComponent,
    PuiTypographyModule,
    PuiSegmentControlTabDirective,
    PlatformStyleModule,
    OfferItemServiceComponent,
    PuiCollapseModule,
    NgTemplateOutlet,
    PuiTagModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientServicesComponent {
  readonly fetchClientServices$ = inject(FetchClientServicesGQL).watch(
    {
      clientId: currentClientId()
    },
    {
      fetchPolicy: 'cache-and-network',
      useInitialLoading: true
    }
  ).valueChanges;

  readonly fetchInactiveClientServices$ = inject(InactiveClientServicesGQL).watch(
    {
      clientId: currentClientId()
    },
    {
      fetchPolicy: 'cache-and-network',
      useInitialLoading: true
    }
  ).valueChanges;

  readonly ClientServiceViewType = ClientServiceViewType;
  readonly activeClientServiceViewType = signal(ClientServiceViewType.PROGRESS);

  readonly route = inject(ActivatedRoute);
}
