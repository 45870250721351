import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { PuiDrawerContentModule, PuiTabsModule } from '@awarenow/profi-ui-core';
import { ClientCardHeaderComponent } from './components/client-card-header/client-card-header.component';
import {
  CLIENT_CARD_DETAILS,
  CLIENT_CARD_ENGAGEMENTS,
  CLIENT_CARD_HISTORY,
  CLIENT_CARD_NOTES,
  CLIENT_CARD_OUTLET
} from '@app/features/client-card/client-card.routes';

@Component({
  selector: 'client-card',
  standalone: true,
  templateUrl: 'client-card.component.html',
  imports: [
    RouterOutlet,
    RouterLink,
    PuiDrawerContentModule,
    PuiTabsModule,
    RouterLinkActive,
    ClientCardHeaderComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientCardComponent {
  readonly tabs = [
    {
      label: 'Details',
      path: [{ outlets: { [CLIENT_CARD_OUTLET]: [CLIENT_CARD_DETAILS] } }]
    },
    {
      label: 'Engagements',
      path: [{ outlets: { [CLIENT_CARD_OUTLET]: [CLIENT_CARD_ENGAGEMENTS] } }]
    },
    {
      label: 'Notes',
      path: [{ outlets: { [CLIENT_CARD_OUTLET]: [CLIENT_CARD_NOTES] } }]
    },
    {
      label: 'History',
      path: [{ outlets: { [CLIENT_CARD_OUTLET]: [CLIENT_CARD_HISTORY] } }]
    }
  ];
}
