@let request = fetchClientServices$ | async;
@let progress = request?.data?.findAllClientServices?.metadata?.progress;

<pui-segmented-control [(value)]="activeClientServiceViewType">
  <a puiSegmentControlTab [value]="ClientServiceViewType.PROGRESS" class="client-engagements-navigation-item">
    <span class="client-engagements-navigation-item__value" pui-typography="h4">{{ progress?.totalProgress }}</span>
    <span pui-typography="label">Total progress</span>
  </a>
  <a puiSegmentControlTab [value]="ClientServiceViewType.AVAILABLE_SESSIONS" class="client-engagements-navigation-item">
    <span class="client-engagements-navigation-item__value" pui-typography="h4">{{ progress?.availableSessions }}</span>
    <span pui-typography="label">Sessions available</span>
  </a>
</pui-segmented-control>

@let isLoading = request?.loading;

<div class="pui-space_top-8">
  @if (!isLoading) {
    @let services = request!.data.findAllClientServices.items;

    <div role="list" class="client-services__list">
      @for (service of services; track service.id) {
        <ng-container [ngTemplateOutlet]="serviceTemplate" [ngTemplateOutletContext]="{ service }" />
      } @empty {
        <!-- TODO: add empty state -->
        NO ACTIVE SERVICES
      }
    </div>

    <div class="pui-space_top-8">
      <pui-collapse headerText="Excluded from progress" [iconSize]="20">
        <ng-template pui-collapse-content let-close="close">
          <div class="pui-padding_top-5">
            @let inactiveServices = fetchInactiveClientServices$ | async;
            @let isLoading = inactiveServices?.loading;

            @if (!isLoading) {
              @let services = inactiveServices?.data?.findAllClientServices.items;

              <div role="list" class="client-services__list">
                @for (service of services; track service.id) {
                  <ng-container [ngTemplateOutlet]="serviceTemplate" [ngTemplateOutletContext]="{ service }" />
                } @empty {
                  NO INACTIVE SERVICES
                }
              </div>
            } @else {
              LOADING...
            }
          </div>
        </ng-template>
      </pui-collapse>
    </div>
  } @else {
    <!-- TODO: add loading state -->
    LOADING...
  }
</div>

<ng-template #serviceTemplate let-service="service">
  <offer-item-service
    [routerLink]="[service.id]"
    [skipLocationChange]="true"
    [title]="service.title"
    [subtitle]="enrolledAt"
    [coverImageUrl]="service.details.coverImage || null"
    defaultIconName="layers">
    <!-- SUBHEADER -->
    <ng-template #enrolledAt>
      Enrolled at {{ service.enrolledAt | platformDate: { hideWeekDay: true } }}

      @let hasTags = service.details.isEngagementArchived || service.details.isClientRemoved;

      @if (hasTags) {
        <div class="offer-item-service__tags">
          <!-- TODO: Cannot pass additional content through ng-content[subheader] -->
          @if (service.details.isEngagementArchived) {
            <pui-tag size="s" background="#FFF2B280" borderColor="#FFF2B280">
              <span pui-typography="label">Engagement is archived</span>
            </pui-tag>
          }

          @if (service.details.isClientRemoved) {
            <pui-tag size="s" background="#FEEEF2" borderColor="#FEEEF2">
              <span pui-typography="label">Client removed</span>
            </pui-tag>
          }
        </div>
      }
    </ng-template>

    <!-- ACTIONS -->
    @switch (activeClientServiceViewType()) {
      @case (ClientServiceViewType.PROGRESS) {
        <pui-progress-icon actions [value]="service.progress" />
      }
      @case (ClientServiceViewType.AVAILABLE_SESSIONS) {
        <span actions>{{ service.upcomingSessions }}</span>
      }
    }
  </offer-item-service>
</ng-template>
