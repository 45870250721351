import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Inject, OnInit } from '@angular/core';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';
import type { GuideClientDetailsModel } from './models/guide-client-details.model';
import { IS_ADMIN_WORKSPACE } from '@app/modules/workspaces/providers/is-admin-workspace';
import { Observable } from 'rxjs';
import { IS_SOLO_WORKSPACE } from '@app/modules/workspaces/providers/is-solo-workspace';
import {
  PUI_IS_DESKTOP,
  PuiButtonModule,
  PuiIconModule,
  PuiInputModule,
  PuiLetModule,
  PuiLinkModule,
  PuiPanelModule,
  PuiPhoneNumberModule,
  PuiTooltipModule,
  PuiTypographyModule
} from '@awarenow/profi-ui-core';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { currentClientId } from '@app/features/client-card/services/current-client-id';
import { ToViewModelPipe } from './pipes/to-view-model.pipe';
import { IsEmptyPipe } from './pipes/is-empty.pipe';
import { GuideClientFieldEditorModule } from './components/guide-client-field-editor/guide-client-field-editor.module';
import { AssignedTeamMemeberSelectorModule } from './components/assigned-team-memeber-selector/assigned-team-memeber-selector.module';
// TODO Refactor required
import { GuideClientTagSelectorModule } from '@app/screens/guide/guide-clients/guide-client/components/client-tag-selector/guide-client-tag-selector.module';
// TODO Refactor required
import { GuideClientStageSelectorModule } from '@app/screens/guide/guide-clients/guide-client/components/guide-client-stage-selector/guide-client-stage-selector.module';
// TODO Refactor required
import { GuideClientsFeaturesService } from '@app/screens/guide/guide-clients/guide-client/services/features/guide-clients-features.service';
// TODO Refactor required
import {
  clientsByClientId,
  guideClientsIsStatusIdle
} from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.selectors';
// TODO Refactor required
import { guideClientsFetchAll } from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.actions';

type ClientFieldKey = keyof GuideClientDetailsModel;

// TODO Rename component to client details
@Component({
  selector: 'client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    PuiLetModule,
    PuiTypographyModule,
    PuiLinkModule,
    PuiButtonModule,
    PuiIconModule,
    PuiTooltipModule,
    PuiInputModule,
    PuiPanelModule,
    ReactiveFormsModule,
    GuideClientFieldEditorModule,
    GuideClientTagSelectorModule,
    GuideClientStageSelectorModule,
    PuiPhoneNumberModule,
    AssignedTeamMemeberSelectorModule,
    ToViewModelPipe,
    IsEmptyPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientDetailsComponent implements OnInit {
  readonly fields: readonly ClientFieldKey[] = [
    'firstName',
    'lastName',
    'email',
    'phone',
    'organization',
    'stage',
    'tag'
  ];

  readonly id = currentClientId();

  readonly client$: Observable<CRMClient> = this.store$
    .select(clientsByClientId)
    .pipe(map(clients => clients[this.id]));

  private currentEditableField: keyof GuideClientDetailsModel | null = null;

  constructor(
    private readonly store$: Store,
    readonly features: GuideClientsFeaturesService,
    @Inject(IS_ADMIN_WORKSPACE) public isWorkspaceAdmin$: Observable<boolean>,
    @Inject(IS_SOLO_WORKSPACE) readonly isSoloWorkspace$: Observable<boolean>,
    @Inject(PUI_IS_DESKTOP) readonly isDesktop$: Observable<boolean>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store$
      .select(guideClientsIsStatusIdle)
      .pipe(withLatestFrom(this.store$.select(clientsByClientId)), take(1))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(([hasLoaded, clients]) => {
        if (!hasLoaded || !clients || (clients && !clients[this.id])) {
          this.store$.dispatch(guideClientsFetchAll());
        }
      });
  }

  /**
   * Show field editor form
   * @param fieldName {keyof GuideClientDetailsModel}
   */
  setCurrentEditableField(fieldName: ClientFieldKey | null): void {
    this.currentEditableField = fieldName;
    this.cdRef.markForCheck();
  }

  isCurrentEditableField(fieldName: ClientFieldKey): boolean {
    return this.currentEditableField === fieldName;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  saveEditFieldForm(id: CRMClient['id'], fieldName: ClientFieldKey, value: string) {
    switch (fieldName) {
      case 'organization':
        this.features.setOrganization(id, value);

        break;
      case 'phone':
        this.features.setPhoneNumber(id, value);

        break;
      case 'firstName':
        this.features.setFirstName(id, value);

        break;
      case 'lastName':
        this.features.setLastName(id, value);

        break;
      case 'email':
        this.features.setEmail(id, value);

        break;
    }

    this.setCurrentEditableField(null);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  closeEditFieldForm() {
    this.setCurrentEditableField(null);
  }
}
