import { Routes } from '@angular/router';
import authRoutes from '@app/features/auth/auth.routes';
import { BookingAuthorizedUserComponent } from './components/booking-authorized-user/booking-authorized-user.component';
import { AUTH_OPTIONS } from '@app/features/auth/providers/auth-options';
import { OrderStep } from '@app/graphql/types';
import { navigateToYourInformation } from '../../services/navigate-to-your-information';
import { enrollmentUserGuard } from '@app/features/enrollment/guards/enrollment-user.guard';

export const YOUR_INFORMATION_ROUTE = 'your-information';

const routes: Routes = [
  {
    path: YOUR_INFORMATION_ROUTE,
    data: { step: OrderStep.YourInformation },
    providers: [
      {
        provide: AUTH_OPTIONS,
        useFactory: () => ({
          onAuthorized: navigateToYourInformation()
        })
      }
    ],
    children: [
      {
        path: '',
        canActivate: [enrollmentUserGuard],
        component: BookingAuthorizedUserComponent
      },
      ...authRoutes,
      {
        path: '**',
        redirectTo: ''
      }
    ]
  }
];

export default routes;
