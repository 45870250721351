import { Directive, inject, input } from '@angular/core';
import { PuiAvatarSizes } from '@awarenow/profi-ui-core';

@Directive({ selector: '[profileCardAvatar]', standalone: true })
export class ProfileCardAvatarDirective {
  size = input<PuiAvatarSizes>(44, {
    alias: 'profileCardAvatarSize'
  });
}

export const profileCardAvatar = () => {
  return inject(ProfileCardAvatarDirective, { optional: true });
};
