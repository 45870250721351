import { Directive, inject, input } from '@angular/core';
import { PuiTypographyVariants } from '@awarenow/profi-ui-core';

@Directive({
  selector: '[profileCardTypography]',
  standalone: true
})
export class ProfileCardTypographyDirective {
  typography = input.required<PuiTypographyVariants>({ alias: 'profileCardTypography' });
}

export const profileCardTypography = () => inject(ProfileCardTypographyDirective, { optional: true });
