import { createReducer, on } from '@ngrx/store';
import { RelationsStoreType } from './commons/types';
import * as actions from './relations-store.actions';
import produce from 'immer';
import { GuideClient, GuideContact, GuideRelationTypes } from '@app/core/users/types';
import { getRelationId } from '@app/screens/guide/guide-clients/guide-client/store/relations-store/utils/get-relation-id';

const initialState: RelationsStoreType = {
  status: 'initial',
  relations: {}
};

export default createReducer(
  initialState,
  on(actions.fetchRelationsSuccess, (state, { relations }) =>
    produce(state, draft => {
      for (const relation of relations) {
        const guideClient =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          relation.type === GuideRelationTypes.GUIDE_CLIENT ? new GuideClient(relation) : new GuideContact(relation);
        // TODO Attention!!! Need rework reducers separately for each model
        draft.relations[getRelationId(guideClient)] = guideClient;
      }
    })
  )
);
