import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PuiDestroyService } from '@awarenow/profi-ui-core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'guide-client-field-editor',
  templateUrl: './guide-client-field-editor.component.html',
  styleUrls: ['./guide-client-field-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PuiDestroyService]
})
export class GuideClientFieldEditorComponent<T> implements OnInit {
  @Input()
  control!: AbstractControl;

  @Output('save')
  saveChange = new EventEmitter<T>();

  @Output('cancel')
  cancelChange = new EventEmitter<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    @Inject(PuiDestroyService) private destroy$: Observable<void>
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ngOnInit() {
    this.control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cdRef.markForCheck();
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  save() {
    if (this.control.invalid) {
      return;
    }

    this.saveChange.emit(this.control.value);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  cancel() {
    this.cancelChange.emit();
  }
}
