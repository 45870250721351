import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import * as moment from 'moment-timezone';
import { DateTime } from 'luxon';
import { TimezoneValue, UserTimezoneStore } from '@libs/core/user-timezone.store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface DateRangeValue {
  dateStart: string | Date;
  dateEnd?: string | Date;
  duration?: number;
}

export function getDateRange(dateRange: DateRangeValue): [Date, Date] {
  const startDate = new Date(dateRange.dateStart);

  if (dateRange.dateEnd) {
    const endDate = new Date(dateRange.dateEnd);
    return [startDate, endDate];
  }

  if (dateRange.duration) {
    const endDate = DateTime.fromJSDate(startDate).plus({ minutes: dateRange.duration }).toJSDate();
    return [startDate, endDate];
  }

  throw new Error('Invalid DateRangeValue object. Either dateEnd or duration should be provided.');
}

@Pipe({
  name: 'dateRange',
  standalone: true
})
export class DateRangePipe implements PipeTransform {
  readonly timezone$!: Observable<TimezoneValue>;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    timezoneStore: UserTimezoneStore
  ) {
    this.timezone$ = timezoneStore.timezone$;
  }

  transform(dateRange: DateRangeValue, startFormat: string, endFormat: string): Observable<string> {
    return this.timezone$.pipe(filter(Boolean)).pipe(
      map((timezone: string) => {
        const [start, end] = getDateRange(dateRange);

        if (!start || !end) {
          return '';
        }

        const timezoneOffset = (value: string | Date) => moment(value).tz(timezone).format('Z');
        const startFormatted = formatDate(start, startFormat, this.locale, timezoneOffset(start));
        const endFormatted = formatDate(end, endFormat, this.locale, timezoneOffset(end));

        return `${startFormatted}${endFormatted ? '-' : ''}${endFormatted}`;
      })
    );
  }
}
