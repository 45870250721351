import { WidgetDirective } from '@app/cdk/widgets';
import { INavigationWidget } from '@app/modules/navigation/interfaces/navigation-widget';
import { Directive, inject } from '@angular/core';
import { PuiDrawerRef } from '@awarenow/profi-ui-core';

@Directive({
  host: {
    '(click)': 'closeSidenav()'
  },
  standalone: true
})
export abstract class NavigationWidgetDirective extends WidgetDirective<INavigationWidget> {
  protected drawerRef = inject(PuiDrawerRef, { optional: true });

  closeSidenav(): void {
    this.drawerRef?.close();
  }
}
