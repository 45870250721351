import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GuideClient, isAssignedToGuide, isGuideContact } from '@app/core/users/types';
import { AuthService } from '@app/core/auth/services';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { fetchRelations } from '@app/screens/guide/guide-clients/guide-client/store/relations-store/relations-store.actions';
import { Store } from '@ngrx/store';
import {
  hasRelationsLoaded,
  relationsSelector
} from '@app/screens/guide/guide-clients/guide-client/store/relations-store/relations-store.selectors';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { getRelationId } from '@app/screens/guide/guide-clients/guide-client/store/relations-store/utils/get-relation-id';
import { GuideClientModule as GuideClientNotesViewModule } from '@app/modules/guide-client/guide-client.module';
import { GuideNotesModule } from '@app/modules/guide-notes/guide-notes.module';
import { RelationsStoreModule } from '@app/screens/guide/guide-clients/guide-client/store/relations-store/relations-store.module';
import { GuideRelationPipe } from '@app/features/client-card/routes/client-notes/pipes/guide-relation.pipe';
import { currentClient } from '@app/features/client-card/services/current-client';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'client-notes',
  templateUrl: './client-notes.component.html',
  styleUrls: ['./client-notes.component.scss'],
  standalone: true,
  imports: [AsyncPipe, GuideClientNotesViewModule, GuideNotesModule, RelationsStoreModule, GuideRelationPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientNotesComponent implements OnInit {
  private currentClient = currentClient();

  relation$ = this.currentClient.pipe(
    switchMap(({ relationId: id, type }) =>
      this.store$.select(relationsSelector).pipe(
        filter(relations => !!relations),
        map(relations => relations[getRelationId({ id, type })])
      )
    )
  );

  readonly searchControl: UntypedFormControl = new UntypedFormControl();

  constructor(
    private readonly authService: AuthService,
    private readonly workspacesService: WorkspacesService,
    private readonly store$: Store
  ) {}

  ngOnInit(): void {
    this.store$
      .select(hasRelationsLoaded)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(hasLoaded => {
        if (!hasLoaded) {
          this.store$.dispatch(fetchRelations());
        }
      });
  }

  canShareNotes(relation: GuideClient): boolean {
    return !isGuideContact(relation);
  }

  isAssigned(relation: GuideClient): boolean {
    return isAssignedToGuide(relation, this.authService.user?.email) || !this.workspacesService.isTeamAdmin;
  }
}
