import { Store } from '@ngrx/store';
import { inject } from '@angular/core';
import { clientsByClientId } from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.selectors';
import { filter, map, tap } from 'rxjs/operators';
import { PUI_DRAWER_DATA } from '@awarenow/profi-ui-core';
import { guideClientsFetchAll } from '@app/screens/guide/guide-clients/guide-client/store/guide-clients-store/guide-clients-store.actions';
import { CRMClient } from '@app/screens/guide/guide-clients/guide-client/services/api/guide-clients-api.service';

export const currentClient = () => {
  const store$ = inject(Store);
  const data: any = inject(PUI_DRAWER_DATA);

  return store$.select(clientsByClientId).pipe(
    map(clients =>
      data.clientId ? Object.values(clients).find(({ clientId }) => clientId === data.clientId)! : clients[data.id]
    ),
    tap(client => {
      if (!client) {
        store$.dispatch(guideClientsFetchAll());
      }
    }),
    filter<CRMClient>(Boolean)
  );
};
