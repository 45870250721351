@let _form = form();

<form [formGroup]="_form" class="pui-form" (ngSubmit)="submit()">
  <div class="pui-form__row">
    <!-- EMAIL -->
    <div class="pui-form__field">
      <label pui-input-label for="email"> Email </label>
      <pui-input-field>
        <input
          id="email"
          formControlName="email"
          pui-input-text
          type="email"
          placeholder="example@mail.com"
          [readonly]="true" />
      </pui-input-field>
    </div>
  </div>

  <div class="pui-form__row">
    <!-- PASSWORD -->
    <div class="pui-form__field">
      <label pui-input-label for="password"> Password </label>
      <pui-input-field>
        <input id="password" formControlName="password" pui-input-text type="password" />
      </pui-input-field>

      @let _validationError = validationError();

      @if (_validationError?.wrongPassword; as errorMessage) {
        <div class="pui-space_top-2">
          <p class="error" pui-typography="body-s" role="alert">
            {{ errorMessage }}
          </p>
        </div>
      }
    </div>
  </div>

  <div class="pui-form__buttons">
    <button pui-button type="submit" [isLoading]="submitting()">Sign in</button>
  </div>
</form>
