import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot, Routes } from '@angular/router';
import clientEngagementsRoutes from '@app/features/client-card/routes/client-engagements/client-engagements.routes';
import { ClientDetailsComponent } from '@app/features/client-card/routes/client-details/client-details.component';
import { ClientNotesComponent } from '@app/features/client-card/routes/client-notes/client-notes.component';
import { GuideClientHistoryComponent } from '@app/features/client-card/routes/client-history/guide-client-history.component';
import { inject } from '@angular/core';
import { ClientCardDrawerService } from '@app/features/client-card/services/client-card-drawer.service';

const resolveClientCardRoute: ResolveFn<void> = ({ queryParamMap }: ActivatedRouteSnapshot) =>
  inject(ClientCardDrawerService).open(
    queryParamMap.keys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: queryParamMap.get(key)
      }),
      {}
    )
  );

export const CLIENT_CARD_OUTLET = 'clientCard';
export const CLIENT_CARD_DETAILS = 'details';
export const CLIENT_CARD_ENGAGEMENTS = 'engagements';
export const CLIENT_CARD_NOTES = 'notes';
export const CLIENT_CARD_HISTORY = 'history';

const routes: Routes = [
  {
    path: '',
    outlet: CLIENT_CARD_OUTLET,
    children: [
      {
        path: CLIENT_CARD_DETAILS,
        component: ClientDetailsComponent,
        resolve: [resolveClientCardRoute]
      },
      {
        path: CLIENT_CARD_ENGAGEMENTS,
        children: clientEngagementsRoutes,
        resolve: [resolveClientCardRoute]
      },
      {
        path: CLIENT_CARD_NOTES,
        component: ClientNotesComponent,
        resolve: [resolveClientCardRoute]
      },
      {
        path: CLIENT_CARD_HISTORY,
        component: GuideClientHistoryComponent,
        resolve: [resolveClientCardRoute]
      }
    ]
  }
];

export default routes;
