import { Directive, inject, input, TemplateRef } from '@angular/core';
import { Profile } from '@app/graphql/types';

type ProfileCardDescriptionType = TemplateRef<{ profile: Profile }>;

@Directive({ selector: '[profileCardSubtitle]', standalone: true })
export class ProfileCardDescriptionDirective {
  template = input.required<ProfileCardDescriptionType>({ alias: 'profileCardSubtitle' });
}

export const profileCardDescription = () => {
  return inject(ProfileCardDescriptionDirective, { optional: true });
};
