import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from '@app/graphql/types';
import { CONFIRMATION_ROUTE } from '../routes/confirmation/confirmation.routes';
import { PAYMENT_ROUTE } from '../routes/payment/payment.routes';
import { ORDER_ID_PARAM } from '../consts';

@Injectable()
export class EnrollmentNavigationService {
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  toConfirmation(orderId: Order['id']) {
    return this.router.navigate([CONFIRMATION_ROUTE], {
      queryParams: { [ORDER_ID_PARAM]: orderId },
      relativeTo: this.route
    });
  }

  toPayment(orderId: Order['id']) {
    return this.router.navigate([PAYMENT_ROUTE], {
      queryParams: { [ORDER_ID_PARAM]: orderId },
      relativeTo: this.route
    });
  }
}
