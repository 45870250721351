import { inject, InjectionToken, Provider, signal, WritableSignal } from '@angular/core';

const ORDER_ID = new InjectionToken<WritableSignal<string | null>>('ORDER_ID');

export const provideOrderId = (): Provider[] => [
  {
    provide: ORDER_ID,
    useValue: signal(null)
  }
];

export const orderId = () => inject(ORDER_ID);
