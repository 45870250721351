import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PuiStepperModule } from '@awarenow/profi-ui-core';
import { currentStep } from './services/current-step.service';
import { map } from 'rxjs/operators';
import { OrderStep } from '@app/graphql/types';
import { AsyncPipe } from '@angular/common';
import { useEnrollmentOfferItem } from '../../services/enrollment-offer-item.query-ref';
interface EnrollmentStepsItem {
  label: string;
  step: OrderStep;
}

const stepsMap: Record<OrderStep, EnrollmentStepsItem> = {
  [OrderStep.YourInformation]: {
    label: 'Your Information',
    step: OrderStep.YourInformation
  },
  [OrderStep.Payment]: {
    label: 'Payment',
    step: OrderStep.Payment
  },
  [OrderStep.Confirmation]: {
    label: 'Confirmation',
    step: OrderStep.Confirmation
  }
};

@Component({
  selector: 'enrollment-steps',
  standalone: true,
  templateUrl: 'enrollment-steps.component.html',
  imports: [PuiStepperModule, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnrollmentStepsComponent {
  private enrollmentOfferItem = useEnrollmentOfferItem();

  protected steps$ = this.enrollmentOfferItem.pipe(map(({ orderSteps }) => orderSteps.map(step => stepsMap[step])));

  protected currentStepIndex$ = this.steps$.pipe(
    map(steps => steps.findIndex(({ step }) => step === this.currentStep() || 0))
  );

  private currentStep = currentStep();
}
