import { inject, Injectable, Provider } from '@angular/core';
import { enrollmentOfferItemId } from './enrollment-offer-item-id';
import { GetEnrollmentOfferItemGQL } from '../__generated__/get-enrollment-offer-item.generated';
import { filter, map } from 'rxjs/operators';

@Injectable()
class EnrollmentOfferItem {
  queryRef = inject(GetEnrollmentOfferItemGQL).watch(
    {
      offerItemId: enrollmentOfferItemId()!
    },
    {
      useInitialLoading: true
    }
  ).valueChanges;
}

export const provideEnrollmentOfferItem = (): Provider[] => [EnrollmentOfferItem];

export const useEnrollmentOfferItem = () =>
  inject(EnrollmentOfferItem).queryRef.pipe(
    map(result => result?.data?.getOfferItem),
    filter(Boolean)
  );

export const useEnrollmentOfferItemLoading = () =>
  inject(EnrollmentOfferItem).queryRef.pipe(map(result => result.loading));
