import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RELATIONS_STORE } from './commons/consts';
import reducer from './relations-store.reducer';
import { RelationsStoreEffects } from './relations-store.effects';
import { RelationsApiService } from './services/relations-api.service';

@NgModule({
  providers: [RelationsApiService],
  imports: [StoreModule.forFeature(RELATIONS_STORE, reducer), EffectsModule.forFeature([RelationsStoreEffects])]
})
export class RelationsStoreModule {}
