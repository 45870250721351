import type { Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { CheckUserExistFormComponent } from '@app/features/auth/components/check-user-exist-form/check-user-exist-form.component';
import { SignInFormComponent } from '@app/features/auth/components/sign-in/sign-in.component';
import { SignUpComponent } from '@app/features/auth/components/sign-up/sign-up.component';

export const AUTH_ROUTE = 'auth';
export const AUTH_OUTLET = 'auth';

const routes: Routes = [
  {
    path: AUTH_ROUTE,
    component: AuthComponent,
    children: [
      {
        path: '',
        outlet: AUTH_OUTLET,
        children: [
          {
            path: '',
            component: CheckUserExistFormComponent
          },
          {
            path: ':email/sign-in',
            component: SignInFormComponent
          },
          {
            path: ':email/sign-up',
            component: SignUpComponent
          }
        ]
      }
    ]
  }
];

export default routes;
