import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, input, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { PuiButtonModule, PuiInputCheckboxModule, PuiInputModule, PuiTypographyModule } from '@awarenow/profi-ui-core';
import { clientSignIn } from '@app/core/auth/services';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { onAuthorized } from '@app/features/auth/providers/auth-options';
import { catchError, EMPTY, Subject } from 'rxjs';

@Component({
  selector: 'sign-in',
  templateUrl: './sign-in.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, PuiButtonModule, PuiInputModule, PuiInputCheckboxModule, PuiTypographyModule]
})
export class SignInFormComponent {
  email = input.required<string>();

  private clientSignIn = clientSignIn();

  protected form = computed(() => {
    return new FormGroup({
      email: new FormControl<string>(this.email(), {
        nonNullable: true
      }),
      password: new FormControl<string>('', {
        validators: [Validators.required],
        nonNullable: true
      })
    });
  });

  protected submitting = signal(false);
  private userAuthorized = onAuthorized();
  private destroyRef = inject(DestroyRef);
  protected validationError = signal<ValidationErrors | null>(null);

  submit() {
    const form = this.form();

    if (form.invalid) {
      return;
    }

    this.submitting.set(true);

    const value = form.value as Required<typeof form.value>;

    this.clientSignIn(value)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(() => {
          this.validationError.set({ wrongPassword: `Passwords are not matching, please check and try again.` });
          this.submitting.set(false);

          return EMPTY;
        })
      )
      .subscribe(result => {
        this.userAuthorized(result);
      });
  }
}
