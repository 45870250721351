import { Component, computed, inject } from '@angular/core';
import { ProfileCardComponent } from '@app/components/profile-card/profile-card.component';
import { ProfileCardActionsDirective } from '@app/components/profile-card/profile-card-actions.directive';
import { ProfileCardAvatarDirective } from '@app/components/profile-card/profile-card-avatar.directive';
import { PuiButtonModule, PuiDropdownModule, PuiIconModule, PuiList2Module } from '@awarenow/profi-ui-core';
import { ProfileCardOptionsDirective } from '@app/components/profile-card/profile-card-options.directive';
import { currentClient } from '@app/features/client-card/services/current-client';
import { AsyncPipe } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { UserNameModule } from '@app/shared/pipes/user-name/user-name.module';
import { UserNamePipe } from '@app/shared/pipes/user-name/user-name.pipe';

@Component({
  selector: 'client-card-header',
  templateUrl: './client-card-header.component.html',
  standalone: true,
  imports: [
    ProfileCardComponent,
    ProfileCardActionsDirective,
    ProfileCardAvatarDirective,
    PuiButtonModule,
    ProfileCardOptionsDirective,
    PuiList2Module,
    PuiIconModule,
    PuiDropdownModule,
    AsyncPipe,
    UserNameModule
  ]
})
export class ClientCardHeaderComponent {
  private userName = inject(UserNamePipe);
  private client = toSignal(currentClient());

  readonly profile = computed(() => {
    const client = this.client()!;

    return {
      id: `${client.id}`,
      name: this.userName.transform(client, { breakFirstWord: true }),
      photo: client.photo
    };
  });

  readonly actions = [
    {
      label: 'Example action',
      icon: 'pui:plus',
      action: () => this.proposeService()
    }
  ];

  proposeService(): void {}
}
