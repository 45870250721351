<h3 class="text-center" pui-typography="h3">Your information</h3>

@let userName = user | userName;

<pui-paper border="default" shape="rounded" class="pui-padding_4 pui-space_top-5">
  <pui-card>
    <pui-card-header>
      <pui-avatar pui-card-header-icon [avatarUrl]="user.photo" [text]="userName" [size]="44" />
      <small pui-card-header-title pui-typography="body-s"> You are booking as </small>
      <span pui-card-header-subheader pui-typography="body-s-bold"> {{ userName }} </span>
      <button pui-card-header-action pui-link (click)="changeAccount()">Change account</button>
    </pui-card-header>
  </pui-card>
</pui-paper>

<div class="pui-space_top-8 text-center">
  <button pui-button (click)="createOrder()" [isLoading]="isCreatingOrder()">
    Continue
    <pui-icon svgIcon="pui:chevron-right" [size]="20" />
  </button>
</div>
