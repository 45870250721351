import { Directive, inject, input } from '@angular/core';
import { PuiTypographyVariants } from '@awarenow/profi-ui-core';

@Directive({
  selector: '[profileCardOptions]',
  standalone: true
})
export class ProfileCardOptionsDirective {
  titleTypography = input<PuiTypographyVariants>('body-s-bold', { alias: 'profileCardTitleTypography' });
}

export const profileCardOptions = () => inject(ProfileCardOptionsDirective, { optional: true });
