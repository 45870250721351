import { Directive, inject, input } from '@angular/core';

@Directive({ selector: '[profileCardActions]', standalone: true })
export class ProfileCardActionsDirective {
  actions = input.required({ alias: 'profileCardActions' });
}

export const profileCardActions = () => {
  return inject(ProfileCardActionsDirective, { optional: true })?.actions;
};
