import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuideClientStageSelectorComponent } from './guide-client-stage-selector.component';
import {
  PuiBadgeModule,
  PuiDropdownModule,
  PuiIconModule,
  PuiLetModule,
  PuiList2Module
} from '@awarenow/profi-ui-core';
import { SharedModule } from '@libs/shared/shared.module';

@NgModule({
  declarations: [GuideClientStageSelectorComponent],
  imports: [CommonModule, PuiDropdownModule, PuiIconModule, PuiBadgeModule, PuiList2Module, SharedModule, PuiLetModule],
  exports: [GuideClientStageSelectorComponent]
})
export class GuideClientStageSelectorModule {}
