import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuideClientFieldEditorComponent } from './guide-client-field-editor.component';
import { PuiButtonModule } from '@awarenow/profi-ui-core';

@NgModule({
  declarations: [GuideClientFieldEditorComponent],
  imports: [CommonModule, PuiButtonModule],
  exports: [GuideClientFieldEditorComponent]
})
export class GuideClientFieldEditorModule {}
