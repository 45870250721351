@let order = order$ | async;

@if (order) {
  <div class="text-center">
    <pui-icon style="color: #0389a4" svgIcon="pui:check-circle-outlined" [size]="64" />
  </div>

  <div class="text-center pui-space_top-3">
    @let enrollmentOfferItem = enrollmentOfferItem$ | async;
    @let servicesCount = order?.clientServices.length;

    @if (enrollmentOfferItem.type === OfferItemType.Bundle) {
      <h2 pui-typography="h2" [ngPlural]="servicesCount">
        <ng-template ngPluralCase="=1"> 1 service is ready to use </ng-template>
        <ng-template ngPluralCase="other">{{ servicesCount }} services are ready to use </ng-template>
      </h2>
    } @else {
      <h2 pui-typography="h2">You successfully enrolled</h2>
    }
  </div>

  <div class="text-center pui-space_top-3">
    <p pui-typography="body-s">
      Access to services is available from the confirmation email and “My services” section in your client portal.
    </p>
  </div>

  <div class="confirmation__services pui-space_top-8">
    @for (service of order?.clientServices; track service.id) {
      <offer-item-service
        [title]="service.title"
        [subtitle]="service.details.label | titlecase"
        [coverImageUrl]="service.details.coverImage || null"
        defaultIconName="layers">
        <button actions pui-button size="s">Open</button>
      </offer-item-service>
    }
  </div>

  <div class="text-right pui-space_top-8">
    <a pui-link
      >My services
      <pui-icon svgIcon="pui:chevron-right" [size]="20" />
    </a>
  </div>
}
