import { Pipe, PipeTransform } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { isEmpty } from 'lodash';

@Pipe({
  name: 'isEmpty',
  standalone: true
})
export class IsEmptyPipe implements PipeTransform {
  transform(value: unknown): boolean {
    return isEmpty(value);
  }
}
