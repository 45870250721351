import { Routes } from '@angular/router';
import { ClientServicesComponent } from './routes/client-services/client-services.component';
import { ClientServiceDetailsComponent } from './routes/client-service-details/client-service-details.component';

export const CLIENT_ENGAGEMENTS_PROGRESS = '';
export const CLIENT_ENGAGEMENT_ID_PARAM = 'engagementId';

const route: Routes = [
  {
    path: CLIENT_ENGAGEMENTS_PROGRESS,
    component: ClientServicesComponent
  },
  {
    path: `:${CLIENT_ENGAGEMENT_ID_PARAM}`,
    // component: ClientServiceDetailsComponent
    loadChildren: () => import('./routes/client-service-details/client-service-details.routes')
  },
  {
    path: '**',
    redirectTo: CLIENT_ENGAGEMENTS_PROGRESS
  }
];

export default route;
