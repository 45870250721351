import { Injectable } from '@angular/core';
import { WorkspacesService } from '@app/modules/workspaces/services/workspaces.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, skip, switchMap, tap } from 'rxjs/operators';
import {
  createClientTag,
  createClientTagError,
  createClientTagSuccess,
  deleteClientTag,
  deleteClientTagError,
  deleteClientTagSuccess,
  fetchClientTags,
  fetchClientTagsError,
  fetchClientTagsSuccess,
  resetClientsTags,
  updateClientTag,
  updateClientTagError,
  updateClientTagSuccess
} from '@app/screens/guide/guide-clients/guide-client/store/client-tags/client-tags-store.actions';
import { CustomFieldsApiService } from '@app/screens/guide/guide-clients/guide-client/services/api/custom-fields-api.service';
import { NotificationsService } from '@awarenow/profi-ui-core';
import { of } from 'rxjs';

const CLIENT_TAGS_KEY = 'client_tags';

@Injectable()
export class ClientTagsStoreEffects {
  readonly fetchAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchClientTags),
      switchMap(() =>
        this.customFieldsApiService.get().pipe(
          map(data => ({
            type: fetchClientTagsSuccess.type,
            tags: data.find(field => field.fieldName === CLIENT_TAGS_KEY)?.values || []
          })),
          catchError(() =>
            of({
              type: fetchClientTagsError.type
            }).pipe(
              tap(() => {
                this.notificationsService.error(`Error while fetching tag!`);
              })
            )
          )
        )
      )
    )
  );

  readonly update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateClientTag),
      switchMap(({ id, update }) =>
        this.customFieldsApiService
          .patch({
            fieldName: CLIENT_TAGS_KEY,
            value: {
              ...update,
              id
            }
          })
          .pipe(
            map(() => ({
              type: updateClientTagSuccess.type
            })),
            catchError(() =>
              of({
                type: updateClientTagError.type
              }).pipe(
                tap(() => {
                  this.notificationsService.error(`Error while updating tag!`);
                })
              )
            )
          )
      )
    )
  );

  readonly create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createClientTag),
      switchMap(({ tag }) =>
        this.customFieldsApiService
          .put({
            fieldName: CLIENT_TAGS_KEY,
            value: { ...tag }
          })
          .pipe(
            map(([{ value: tag }]) => ({
              type: createClientTagSuccess.type,
              tag
            })),
            catchError(() =>
              of({
                type: createClientTagError.type,
                tag
              }).pipe(
                tap(() => {
                  this.notificationsService.error(`Error while creating tag!`);
                })
              )
            )
          )
      )
    )
  );

  readonly delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteClientTag),
      switchMap(({ id }) =>
        this.customFieldsApiService
          .delete({
            fieldName: CLIENT_TAGS_KEY,
            value: { id }
          })
          .pipe(
            map(() => ({
              type: deleteClientTagSuccess.type
            })),
            catchError(() =>
              of({
                type: deleteClientTagError.type
              }).pipe(
                tap(() => {
                  this.notificationsService.error(`Error while deleting tag!`);
                })
              )
            )
          )
      )
    )
  );

  readonly workspaceChange$ = createEffect(() =>
    this.workspacesService.activeWorkspace$.pipe(
      skip(1),
      map(() => ({
        type: resetClientsTags.type
      }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly customFieldsApiService: CustomFieldsApiService,
    private readonly notificationsService: NotificationsService,
    private readonly workspacesService: WorkspacesService
  ) {}
}
