import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { YOUR_INFORMATION_ROUTE } from '../routes/your-information/your-information.routes';

export const navigateToYourInformation = () => {
  const router = inject(Router);

  return () => {
    const url = router.url.replace(new RegExp(`^(.+\/${YOUR_INFORMATION_ROUTE}\/).+`), '$1');

    return router.navigate(url.split('/').filter(Boolean), {
      queryParamsHandling: 'merge'
    });
  };
};
